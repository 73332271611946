import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.CARDS;
const act_getECards = "/e-card";
const act_getCardInfo = "/card-info";
const act_activate_card = "/activate-card";
const act_rollback_card_activation = "/rollback-card-activation";
const act_deliver_card = "/deliver-card";
const act_rollback_card_delivery = "/rollback-card-delivery";
const act_return_card = "/return-card";
const act_rollback_return_card = "/rollback-return-card";
const act_cancel_card = "/cancel-card";
const act_rollback_card_cancellation = "/rollback-card-cancellation";
const act_download_issued_cards_report = "/download-issued-cards-report";
const act_download_cancelled_cards_report = "/download-cancelled-cards-report";
const act_download_returned_cards_report = "/download-returned-cards-report";
const act_download_printed_cards_report = "/download-printed-cards-report";
const act_download_card_information_report =
  "/download-card-information-report";

const getECards = (cardNumber, visa) => {
  if (visa)
    return apiClient.get(
      endpoint + "/" + cardNumber + act_getECards,
      {},
      {
        headers: {
          "etpa-visa": visa,
        },
      }
    );
  else return apiClient.get(endpoint + "/" + cardNumber + act_getECards);
};

const getCardInfo = (cardNumber) => {
  return apiClient.get(endpoint + "/" + cardNumber + "/" + act_getCardInfo);
};

const getCards = (criteria) => {
  return apiClient.post(endpoint, JSON.stringify(criteria), {
    headers: { "X-HTTP-Method-Override": "GET" },
  });
};

const getCard = (cardID) => {
  return apiClient.get(endpoint + "/" + cardID);
};

const activateCard = (cardID) => {
  return apiClient.put(endpoint + "/" + cardID + act_activate_card);
};

const rollbackCardActivation = (cardID) => {
  return apiClient.put(endpoint + "/" + cardID + act_rollback_card_activation);
};

const deliverCard = (cardID, data) => {
  return apiClient.put(endpoint + "/" + cardID + act_deliver_card, data);
};

const rollbackCardDelivery = (cardID) => {
  return apiClient.put(endpoint + "/" + cardID + act_rollback_card_delivery);
};

const returnCard = (cardID, data) => {
  return apiClient.put(endpoint + "/" + cardID + act_return_card, data);
};

const rollbackReturnCard = (cardID) => {
  return apiClient.put(endpoint + "/" + cardID + act_rollback_return_card);
};

const cancelCard = (cardID, data) => {
  return apiClient.put(endpoint + "/" + cardID + act_cancel_card, data);
};

const rollbackCardCancellation = (cardID) => {
  return apiClient.put(
    endpoint + "/" + cardID + act_rollback_card_cancellation
  );
};

const downloadIssuedCardsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_issued_cards_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadCancelledCardsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_cancelled_cards_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadReturnedCardsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_returned_cards_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPrintedCardsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_printed_cards_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadCardInformationReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_card_information_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const cardsApi = {
  getECards,
  getCardInfo,
  getCards,
  getCard,
  activateCard,
  rollbackCardActivation,
  deliverCard,
  rollbackCardDelivery,
  returnCard,
  rollbackReturnCard,
  cancelCard,
  rollbackCardCancellation,
  downloadIssuedCardsReport,
  downloadCancelledCardsReport,
  downloadReturnedCardsReport,
  downloadPrintedCardsReport,
  downloadCardInformationReport,
};

export default cardsApi;
