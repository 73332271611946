import { Dispatch, SetStateAction, useContext, useState } from "react";
import { LocalizationContext } from "../../../common/context/localizationContext";
import AppCard from "../../../common/components/cards/AppCard";
import ECardModel from "../../../interfaces/eCards/ECardModel";
import AppExpansionPanel from "../../../common/components/expansionPanel/AppExpansionPanel";
import ECardBenefitCoverageInfo from "./ECardBenefitCoverageInfo";
import ECardProductCoverageModel from "../../../interfaces/eCards/ECardProductCoverageModel";
import styleConstants from "../../../common/constants/styleConstants";
import strings from "../../../base/strings";
import { ApplicationContext } from "./../../../common/context/applicationContext";

interface Props {
  eCard: ECardModel | null;
  eCardProductCoverage: ECardProductCoverageModel;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}
export default function ECardProductCoverageInfo({
  eCard,
  eCardProductCoverage,
}: // loading,
// setLoading,
Props) {
  const { getName, translate } = useContext(LocalizationContext);
  const { formatNumber } = useContext(ApplicationContext);
  const [isBenefitCoveragesExpanded, setIsBenefitCoveragesExpanded] =
    useState<boolean>(false);

  return (
    <AppCard>
      {/* <AppStackLayout orientation="vertical" gap={styleConstants.GAP_LARGE}> */}
      {/* <LabelTitle
          title={getName(eCardProductCoverage.InsuranceProductModel)}
          style={{ marginTop: styleConstants.PADDING_LARGE }}
        /> */}
      <AppExpansionPanel
        // title={strings.BENEFITS}
        title={
          getName(eCardProductCoverage.InsuranceProductModel) +
          (eCardProductCoverage.LimitPerInsured
            ? " (" +
              translate(strings.LIMIT_PER_INSURED) +
              ": " +
              formatNumber(eCardProductCoverage.LimitPerInsured) +
              " " +
              getName(eCardProductCoverage.CurrencyModel) +
              ")"
            : "")
        }
        expanded={isBenefitCoveragesExpanded}
        onAction={(e) => {
          setIsBenefitCoveragesExpanded(!e.expanded);
        }}
        overrideTextTransformation
        // subtitle={
        //   eCardProductCoverage.LimitPerInsured
        //     ? " (" +
        //       translate(strings.LIMIT_PER_INSURED) +
        //       ": " +
        //       formatNumber(eCardProductCoverage.LimitPerInsured) +
        //       " " +
        //       getName(eCardProductCoverage.CurrencyModel) +
        //       ") "
        //     : ""
        // }
        style={{ paddingBottom: styleConstants.GAP_LARGE }}
      >
        {eCardProductCoverage?.ECardBenefitCoverageModelList &&
        eCardProductCoverage?.ECardBenefitCoverageModelList.length > 0 ? (
          eCardProductCoverage?.ECardBenefitCoverageModelList.map((item, i) => (
            <ECardBenefitCoverageInfo
              eCard={eCard}
              eCardBenefitCoverage={item}
              // loading={loading}
              // setLoading={setLoading}
              key={i}
            />
          ))
        ) : (
          <></>
        )}
      </AppExpansionPanel>
      {/* </AppStackLayout> */}
    </AppCard>
  );
}
