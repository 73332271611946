import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.CHECKUP;
const act_checkup_agreements = "/checkup-agreements";
const act_checkup_invoices = "/checkup-invoices";
const act_delete_checkup_agreement = "/delete-checkup-agreement";
const route_checkup_agreement = "/checkup-agreement";
const act_update_checkup_agreement = "/update-checkup-agreement";
const act_checkout_checkup_agreement = "/checkout-checkup-agreement";
const act_checkin_checkup_agreement = "/checkin-checkup-agreement";
const act_issue_checkup_agreement = "/issue-checkup-agreement";
const act_rollback_issue_checkup_agreement =
  "/rollBack-issue-checkup-agreement";
const act_update_admin_cost_charges = "/update-admin-cost-charges";
const act_recalculate_checkup = "/recalculate-checkup";
const route_checkup_agreement_service = "/checkup-agreement-service";
const act_delete_checkup_agreement_service =
  "/delete-checkup-agreement-service";
const act_update_checkup_agreement_service =
  "/update-checkup-agreement-service";
const route_checkup_agreement_provider = "/checkup-agreement-provider";
const act_delete_checkup_agreement_provider =
  "/delete-checkup-agreement-provider";
const act_update_checkup_agreement_provider =
  "/update-checkup-agreement-provider";
const route_checkup_agreement_provider_service =
  "/checkup-agreement-provider-service";
const act_delete_checkup_agreement_provider_service =
  "/delete-checkup-agreement-provider-service";
const act_update_checkup_agreement_provider_service =
  "/update-checkup-agreement-provider-service";
const route_provider = "/provider";
const route_checkup_offers = "/checkup-offers";
const route_checkup_offer = "/checkup-offer";
const act_delete_checkup_offer = "/delete-checkup-offer";
const act_update_checkup_offer = "/update-checkup-offer";
const route_checkup_offer_provider = "/checkup-offer-provider";
const act_delete_checkup_offer_provider = "/delete-checkup-offer-provider";
const act_update_checkup_agreement_tax = "/update-checkup-agreement-tax";
const route_checkup_agreement_tax = "/checkup-agreement-tax";
const act_delete_checkup_agreement_tax = "/delete-checkup-agreement-tax";
const route_checkup_offer_providers = "/checkup-offer-providers";
const route_checkup_agreement_taxes = "/checkup-agreement-taxes";
const act_update_checkup_offer_provider = "/update-checkup-offer-provider";
const act_confirm_checkup_offer = "/confirm-checkup-offer";
const act_rollback_checkup_offer = "/rollback-checkup-offer";
const route_checkup_people = "/checkup-agreement-people";
const route_checkup_agreement_person = "/checkup-agreement-person";
const act_update_checkup_agreement_person = "/update-checkup-agreement-person";
const act_delete_checkup_agreement_person = "/delete-checkup-agreement-person";
const route_checkup_letters = "/checkup-letters";
const route_checkup_letter = "/checkup-letter";
const act_delete_checkup_letter = "/delete-checkup-letter";
const act_generate_checkup_letters = "/generate-checkup-letters";
const act_extend_checkup_letter_validity = "/extend-checkup-letter-validity";
const route_checkup_results = "/checkup-results";
const route_checkup_result = "/checkup-result";
const act_delete_checkup_result = "/delete-checkup-result";
const act_update_checkup_result = "/update-checkup-result";
const route_checkup_result_service = "/checkup-result-service";
const act_delete_checkup_result_service = "/delete-checkup-result-service";
const act_update_checkup_result_service = "/update-checkup-result-service";
const act_send_checkup_letters = "/send-checkup-letters";
const act_print_checkup_letters = "/print-checkup-letters";
const act_rollback_send_checkup_letters = "/rollback-send-checkup-letters";
const act_rollback_print_checkup_letters = "/rollback-print-checkup-letters";
const act_apply_checkup_agreement_provider_discount =
  "/apply-checkup-agreement-provider-discount";
const act_remove_checkup_agreement_provider_discount =
  "/remove-checkup-agreement-provider-discount";
const route_checkup_attachments = "/checkup-attachments";
const act_delete_checkup_attachment = "/delete-checkup-attachment";
const route_checkup_attachment = "/checkup-attachment";
const act_update_checkup_attachment = "/update-checkup-attachment";
const route_attachment = "/attachment";
const route_checkup_invoice = "/checkup-invoice";
const act_delete_checkup_invoice = "/delete-checkup-invoice";
const route_checkup_invoices = "checkup-invoices";
const act_rollback_checkup_invoice = "/rollback-checkup-invoice";
const act_submit_checkup_invoice = "/submit-checkup-invoice";
const route_checkup_invoice_result = "/checkup-invoice-result";
const act_delete_checkup_invoice_result = "/delete-checkup-invoice-result";
const act_recalculate_checkup_invoice = "/recalculate-checkup-invoice";
const route_checkup_invoice_results = "/checkup-invoice-results";
const route_checkup_invoice_providers = "/checkup-invoice-providers";
const route_checkup_invoice_taxes = "/checkup-invoice-taxes";
const route_download_checkup_letter_report = "/download-checkup-letter-report";
const route_copy_download_checkup_letter_report =
  "/download-copy-checkup-letter-report";
const route_download_checkup_offer_report = "/download-checkup-offer-report";
const act_import_checkup_agreement_person = "/import-checkup-agreement-person";
const act_commit_checkup_agreement_person_workspace =
  "/commit-checkup-agreement-person-workspace";
const route_checkup_results_not_related_to_invoice =
  "/checkup-results-not-related-to-invoice";
const route_sent_checkup_letters = "/sent-checkup-letters";
const act_close_checkup_agreement = "/close-checkup-agreement";
const act_open_checkup_agreement = "/open-checkup-agreement";

const getCheckupAgreements = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_checkup_agreements,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getCheckupInvoices = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_checkup_invoices,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const deleteCheckupAgreement = (checkupAgreementID, criteria) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_delete_checkup_agreement,
    JSON.stringify({
      ...criteria,
    })
  );
};

const createNewCheckupAgreement = (criteria) => {
  return apiClient.post(
    endpoint + route_checkup_agreement,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateCheckupAgreement = (checkupAgreementID, criteria) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_update_checkup_agreement,
    JSON.stringify({
      ...criteria,
    })
  );
};
const getCheckupAgreement = (checkupAgreementID) => {
  return apiClient.get(
    endpoint + route_checkup_agreement + "/" + checkupAgreementID
  );
};

const checkinCheckupAgreement = (checkupAgreementID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_checkin_checkup_agreement
  );
};

const checkoutCheckupAgreement = (checkupAgreementID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_checkout_checkup_agreement
  );
};

const issueCheckupAgreement = (checkupAgreementID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_issue_checkup_agreement
  );
};

const rollBackIssueCheckupAgreement = (checkupAgreementID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_rollback_issue_checkup_agreement
  );
};

const updateCheckupAgreementAdministrationCostAndCharges = (
  checkupAgreementID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_update_admin_cost_charges,
    JSON.stringify({
      ...criteria,
    })
  );
};

const recalculateCheckup = (checkupAgreementID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_recalculate_checkup
  );
};

const addCheckupAgreementService = (checkupAgreementID, criteria) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_service,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateCheckupAgreementService = (
  checkupAgreementID,
  checkupAgreementServiceID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_service +
      "/" +
      checkupAgreementServiceID +
      act_update_checkup_agreement_service,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteCheckupAgreementService = (
  checkupAgreementServiceID,
  checkupAgreementID
) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_service +
      "/" +
      checkupAgreementServiceID +
      act_delete_checkup_agreement_service
  );
};

const addCheckupAgreementProvider = (checkupAgreementID, criteria) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_provider,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateCheckupAgreementProvider = (
  checkupAgreementID,
  checkupAgreementProviderID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_provider +
      "/" +
      checkupAgreementProviderID +
      act_update_checkup_agreement_provider,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteCheckupAgreementProvider = (
  checkupAgreementProviderID,
  checkupAgreementID
) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_provider +
      "/" +
      checkupAgreementProviderID +
      act_delete_checkup_agreement_provider
  );
};

const addCheckupAgreementProviderService = (
  checkupAgreementID,
  checkupAgreementProviderID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_provider +
      "/" +
      checkupAgreementProviderID +
      route_checkup_agreement_provider_service,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateCheckupAgreementProviderService = (
  checkupAgreementID,
  checkupAgreementProviderID,
  checkupAgreementProviderServiceID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_provider +
      "/" +
      checkupAgreementProviderID +
      route_checkup_agreement_provider_service +
      "/" +
      checkupAgreementProviderServiceID +
      act_update_checkup_agreement_provider_service,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteCheckupAgreementProviderService = (
  checkupAgreementProviderServiceID,
  checkupAgreementID,
  checkupAgreementProviderID
) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_provider +
      "/" +
      checkupAgreementProviderID +
      route_checkup_agreement_provider_service +
      "/" +
      checkupAgreementProviderServiceID +
      act_delete_checkup_agreement_provider_service
  );
};

const getCheckupAgreementProviderByProviderID = (
  checkupAgreementID,
  providerID
) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      "/" +
      route_provider +
      "/" +
      providerID
  );
};

const getCheckupOffers = (checkupAgreementID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_offers
  );
};

const deleteCheckupOffer = (checkupOfferID, checkupAgreementID) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_offer +
      "/" +
      checkupOfferID +
      act_delete_checkup_offer
  );
};

const updateCheckupOffer = (checkupOfferID, criteria) => {
  return apiClient.put(
    endpoint +
      route_checkup_offer +
      "/" +
      checkupOfferID +
      act_update_checkup_offer,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteCheckupOfferProvider = (checkupOfferProviderID, checkupOfferID) => {
  return apiClient.delete(
    endpoint +
      route_checkup_offer +
      "/" +
      checkupOfferID +
      route_checkup_offer_provider +
      "/" +
      checkupOfferProviderID +
      act_delete_checkup_offer_provider
  );
};

// const updateCheckupOfferProvider = (
//   checkupOfferID,
//   criteria
// ) => {
//   return apiClient.put(
//     endpoint +
//       route_checkup_offer +
//       "/" +
//       checkupOfferID +
//       act_update_checkup_offer_provider,
//     JSON.stringify({
//       ...criteria,
//     })
//   );
// };

const addCheckupOfferProvider = (
  checkupOfferID,
  checkupAgreementProviderID
) => {
  return apiClient.post(
    endpoint +
      route_checkup_offer +
      "/" +
      checkupOfferID +
      route_checkup_agreement_provider +
      "/" +
      checkupAgreementProviderID +
      route_checkup_offer_provider
  );
};

const updateCheckupOfferProvider = (
  checkupOfferID,
  checkupAgreementProviderID
) => {
  return apiClient.put(
    endpoint +
      route_checkup_offer +
      "/" +
      checkupOfferID +
      route_checkup_agreement_provider +
      "/" +
      checkupAgreementProviderID +
      act_update_checkup_offer_provider
  );
};

const addCheckupAgreementTax = (checkupAgreementID, criteria) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_tax,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteCheckupAgreementTax = (
  checkupAgreementTaxID,
  checkupAgreementID
) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_tax +
      "/" +
      checkupAgreementTaxID +
      act_delete_checkup_agreement_tax
  );
};

const updateCheckupAgreementTax = (
  checkupAgreementTaxID,
  checkupAgreementID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_tax +
      "/" +
      checkupAgreementTaxID +
      act_update_checkup_agreement_tax,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getCheckupOfferProviders = (checkupOfferID) => {
  return apiClient.get(
    endpoint +
      route_checkup_offer +
      "/" +
      checkupOfferID +
      route_checkup_offer_providers
  );
};

const getCheckupAgreementTaxes = (checkupAgreementID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_taxes
  );
};

const confirmCheckupOffer = (checkupAgreementID, checkupOfferID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_offer +
      "/" +
      checkupOfferID +
      act_confirm_checkup_offer
  );
};

const rollbackCheckupOffer = (checkupAgreementID, checkupOfferID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_offer +
      "/" +
      checkupOfferID +
      act_rollback_checkup_offer
  );
};

const getCheckupAgreementPeople = (checkupAgreementID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_people
  );
};

const addCheckupAgreementPerson = (checkupAgreementID, criteria) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_person,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteCheckupAgreementPerson = (
  checkupAgreementPersonID,
  checkupAgreementID
) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_person +
      "/" +
      checkupAgreementPersonID +
      act_delete_checkup_agreement_person
  );
};

const updateCheckupAgreementPerson = (
  checkupAgreementPersonID,
  checkupAgreementID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_person +
      "/" +
      checkupAgreementPersonID +
      act_update_checkup_agreement_person,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getCheckupLetters = (checkupAgreementID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_letters
  );
};

const getSentCheckupLetters = (checkupAgreementID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_sent_checkup_letters
  );
};

const addCheckupLetter = (checkupAgreementID, criteria) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_letter,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteCheckupLetter = (checkupLetterID, checkupAgreementID) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_letter +
      "/" +
      checkupLetterID +
      act_delete_checkup_letter
  );
};

const generateCheckupLetter = (checkupAgreementID) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_generate_checkup_letters
  );
};

const extendCheckupLetterValidity = (
  checkupLetterID,
  checkupAgreementID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_letter +
      "/" +
      checkupLetterID +
      act_extend_checkup_letter_validity,

    JSON.stringify({
      ...criteria,
    })
  );
};

const getCheckupResults = (checkupAgreementID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_results
  );
};

const getCheckupResultsNotRelatedToInvoice = (checkupAgreementID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_results_not_related_to_invoice
  );
};

const updateCheckupResult = (checkupAgreementID, checkupResultID, criteria) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_result +
      "/" +
      checkupResultID +
      act_update_checkup_result,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteCheckupResult = (checkupResultID, checkupAgreementID) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_result +
      "/" +
      checkupResultID +
      act_delete_checkup_result
  );
};

const deleteCheckupResultService = (
  checkupResultServiceID,
  checkupResultID,
  checkupAgreementID
) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_result +
      "/" +
      checkupResultID +
      route_checkup_result_service +
      "/" +
      checkupResultServiceID +
      act_delete_checkup_result_service
  );
};

const updateCheckupResultService = (
  checkupAgreementID,
  checkupResultID,
  checkupResultServiceID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_result +
      "/" +
      checkupResultID +
      route_checkup_result_service +
      "/" +
      checkupResultServiceID +
      act_update_checkup_result_service,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addCheckupResultService = (
  checkupAgreementID,
  checkupResultID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_result +
      "/" +
      checkupResultID +
      route_checkup_result_service,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getCheckupResult = (checkupResultID) => {
  return apiClient.get(endpoint + route_checkup_result + "/" + checkupResultID);
};

const addCheckupResults = (checkupAgreementID, criteria) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_result,
    JSON.stringify({
      ...criteria,
    })
  );
};

const printCheckupLetters = (checkupAgreementID, criteria) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_print_checkup_letters,
    JSON.stringify({
      ...criteria,
    })
  );
};

const sendCheckupLetters = (checkupAgreementID, criteria) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_send_checkup_letters,
    JSON.stringify({
      ...criteria,
    })
  );
};

const rollBackSentCheckupLetters = (checkupAgreementID, criteria) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_rollback_send_checkup_letters,
    JSON.stringify({
      ...criteria,
    })
  );
};

const rollBackPrintCheckupLetters = (checkupAgreementID, criteria) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_rollback_print_checkup_letters,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addCheckupOffers = (checkupAgreementID, criteria) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_offer,
    JSON.stringify({
      ...criteria,
    })
  );
};

const removeCheckupAgreementDiscount = (
  checkupAgreementID,
  checkupAgreementProviderID
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_provider +
      "/" +
      checkupAgreementProviderID +
      act_remove_checkup_agreement_provider_discount
  );
};

const applyCheckupAgreementDiscount = (
  checkupAgreementID,
  checkupAgreementProviderID
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_agreement_provider +
      "/" +
      checkupAgreementProviderID +
      act_apply_checkup_agreement_provider_discount
  );
};

const getCheckupAttachments = (checkupAgreementID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_attachments
  );
};

const deleteCheckupAttachment = (checkupAgreementID, checkupAttachmentID) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_attachment +
      "/" +
      checkupAttachmentID +
      act_delete_checkup_attachment
  );
};

const updateCheckupAttachment = (
  checkupAttachmentID,
  checkupResultID,
  checkupAgreementID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_result +
      "/" +
      checkupResultID +
      route_checkup_attachment +
      "/" +
      checkupAttachmentID +
      act_update_checkup_attachment,

    JSON.stringify({
      ...criteria,
    })
  );
};

const addCheckupAttachment = (checkupAgreementID, attachmentID, criteria) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_attachment +
      "/" +
      attachmentID +
      route_checkup_attachment,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getCheckupAgreementInvoices = (checkupAgreementID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      "/" +
      route_checkup_invoices
  );
};

const addCheckupInvoice = (checkupAgreementID, criteria) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_invoice,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteCheckupInvoice = (checkupInvoiceID, checkupAgreementID) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_invoice +
      "/" +
      checkupInvoiceID +
      act_delete_checkup_invoice
  );
};

const submitCheckupInvoice = (checkupInvoiceID, checkupAgreementID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_invoice +
      "/" +
      checkupInvoiceID +
      act_submit_checkup_invoice
  );
};

const rollbackCheckupInvoice = (checkupInvoiceID, checkupAgreementID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_invoice +
      "/" +
      checkupInvoiceID +
      act_rollback_checkup_invoice
  );
};

const getCheckupInvoiceResults = (checkupInvoiceID) => {
  return apiClient.get(
    endpoint +
      route_checkup_invoice +
      "/" +
      checkupInvoiceID +
      "/" +
      route_checkup_invoice_results
  );
};

const deleteCheckupInvoiceResult = (
  checkupInvoiceResultID,
  checkupInvoiceID,
  checkupAgreementID
) => {
  return apiClient.delete(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_invoice +
      "/" +
      checkupInvoiceID +
      route_checkup_invoice_result +
      "/" +
      checkupInvoiceResultID +
      act_delete_checkup_invoice_result
  );
};

const downloadCheckupLetterReport = (checkupAgreementID, checkupLetterID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_letter +
      "/" +
      checkupLetterID +
      route_download_checkup_letter_report,
    null,
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
    }
  );
};

const downloadCopyCheckupLetterReport = (
  checkupAgreementID,
  checkupLetterID
) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_letter +
      "/" +
      checkupLetterID +
      route_copy_download_checkup_letter_report,
    null,
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
    }
  );
};

const addCheckupInvoiceResult = (
  checkupInvoiceID,
  checkupAgreementID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_invoice +
      "/" +
      checkupInvoiceID +
      route_checkup_invoice_result,
    JSON.stringify({
      ...criteria,
    })
  );
};

const recalculateCheckupInvoice = (checkupInvoiceID, checkupAgreementID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_invoice +
      "/" +
      checkupInvoiceID +
      act_recalculate_checkup_invoice
  );
};

const getCheckupInvoiceProviders = (checkupInvoiceID) => {
  return apiClient.get(
    endpoint +
      route_checkup_invoice +
      "/" +
      checkupInvoiceID +
      "/" +
      route_checkup_invoice_providers
  );
};

const getCheckupInvoiceTaxes = (checkupInvoiceID) => {
  return apiClient.get(
    endpoint +
      route_checkup_invoice +
      "/" +
      checkupInvoiceID +
      "/" +
      route_checkup_invoice_taxes
  );
};

const downloadCheckupOfferReport = (checkupAgreementID, checkupOfferID) => {
  return apiClient.get(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      route_checkup_offer +
      "/" +
      checkupOfferID +
      route_download_checkup_offer_report,
    null,
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
    }
  );
};

const importCheckupAgreementPerson = (formData, onUploadProgress) => {
  return apiClient.post(
    endpoint + act_import_checkup_agreement_person,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const commitCheckupAgreementPersonWorkspace = (
  checkupAgreementID,
  acceptedCheckup
) => {
  return apiClient.post(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_commit_checkup_agreement_person_workspace,
    JSON.stringify({ AcceptedCheckupAgreementPeople: acceptedCheckup })
  );
};

const closeCheckupAgreement = (checkupAgreementID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_close_checkup_agreement
  );
};

const openCheckupAgreement = (checkupAgreementID) => {
  return apiClient.put(
    endpoint +
      route_checkup_agreement +
      "/" +
      checkupAgreementID +
      act_open_checkup_agreement
  );
};

const checkupApi = {
  getCheckupAgreements,
  getCheckupInvoices,
  createNewCheckupAgreement,
  deleteCheckupAgreement,
  updateCheckupAgreement,
  getCheckupAgreement,
  checkoutCheckupAgreement,
  checkinCheckupAgreement,
  issueCheckupAgreement,
  updateCheckupAgreementAdministrationCostAndCharges,
  recalculateCheckup,
  deleteCheckupAgreementService,
  addCheckupAgreementService,
  updateCheckupAgreementService,
  addCheckupAgreementProvider,
  updateCheckupAgreementProvider,
  deleteCheckupAgreementProvider,
  deleteCheckupAgreementProviderService,
  addCheckupAgreementProviderService,
  updateCheckupAgreementProviderService,
  getCheckupAgreementProviderByProviderID,
  getCheckupOffers,
  deleteCheckupOffer,
  updateCheckupOffer,
  deleteCheckupOfferProvider,
  addCheckupOfferProvider,
  addCheckupAgreementTax,
  updateCheckupAgreementTax,
  deleteCheckupAgreementTax,
  getCheckupOfferProviders,
  getCheckupAgreementTaxes,
  updateCheckupAgreementProvider,
  rollBackIssueCheckupAgreement,
  confirmCheckupOffer,
  rollbackCheckupOffer,
  updateCheckupOfferProvider,
  getCheckupAgreementPeople,
  addCheckupAgreementPerson,
  updateCheckupAgreementPerson,
  deleteCheckupAgreementPerson,
  getCheckupLetters,
  generateCheckupLetter,
  addCheckupLetter,
  deleteCheckupLetter,
  extendCheckupLetterValidity,
  updateCheckupResult,
  deleteCheckupResult,
  getCheckupResults,
  addCheckupResultService,
  deleteCheckupResultService,
  updateCheckupResultService,
  getCheckupResult,
  addCheckupResults,
  sendCheckupLetters,
  printCheckupLetters,
  rollBackSentCheckupLetters,
  rollBackPrintCheckupLetters,
  addCheckupOffers,
  applyCheckupAgreementDiscount,
  removeCheckupAgreementDiscount,
  getCheckupAttachments,
  addCheckupAttachment,
  updateCheckupAttachment,
  deleteCheckupAttachment,
  getCheckupAgreementInvoices,
  addCheckupInvoice,
  deleteCheckupInvoice,
  submitCheckupInvoice,
  rollbackCheckupInvoice,
  getCheckupInvoiceResults,
  deleteCheckupInvoiceResult,
  addCheckupInvoiceResult,
  recalculateCheckupInvoice,
  getCheckupInvoiceProviders,
  getCheckupInvoiceTaxes,
  downloadCheckupLetterReport,
  downloadCopyCheckupLetterReport,
  downloadCheckupOfferReport,
  importCheckupAgreementPerson,
  commitCheckupAgreementPersonWorkspace,
  getCheckupResultsNotRelatedToInvoice,
  getSentCheckupLetters,
  closeCheckupAgreement,
  openCheckupAgreement,
};

export default checkupApi;
