import { ApiResponse } from "apisauce";
import { errorStatuses } from "../base/errorStatuses";
import uiHelper from "../helpers/uiHelper";

const getErrorMessage = (result: ApiResponse<any, any>) => {
  if (result.status === errorStatuses.BAD_REQUEST) {
    // BadRequest
    if (result.data) {
      if (result.data instanceof ArrayBuffer) {
        // in case of reports when downloading files (default response type is arraybuffer) (NO_DATA_FOUND)
        const arrayBufferConverted = JSON.parse(
          String.fromCharCode.apply(
            null,
            Array.from(new Uint8Array(result.data))
          )
        );
        if (arrayBufferConverted && arrayBufferConverted.Message) {
          // const error = errorCodes[arrayBufferConverted.Message];
          // if (error)
          return arrayBufferConverted.Message;
        }
      } else if (result.data.Message) {
        return getSingleErrorMessage(result.data.Message);
      } else if (typeof result.data === "string")
        return getSingleErrorMessage(result.data);
    }
    return "unable_to_complete_request";
  }
  if (result.status === errorStatuses.AUTHENTICATION_ERROR)
    return "invalid_credentials";

  if (
    result.status === errorStatuses.TOKEN_EXPIRED ||
    result.status === errorStatuses.SESSION_EXPIRED
  )
    return "SESSION_EXPIRED";

  if (result.status === errorStatuses.ACCOUNT_SUSPENDED)
    return "ACCOUNT_SUSPENDED";

  if (result.status === errorStatuses.USER_IS_DISABLED)
    return "USER_IS_DISABLED";

  if (result.status === errorStatuses.FORBIDDEN) {
    // this error status is returned only incase the user does not have access to action (it is returned as key value pair)
    // ActionAuthorizationFilter
    if (
      result.originalError?.response?.statusText &&
      uiHelper.errorCodeHasKey(result.originalError.response.statusText)
    ) {
      return result.originalError.response.statusText;
    }
    return "user_unauthorized";
  }

  return "something_wrong"; // 404 --> CLIENT_ERROR, 500 --> SERVER_ERROR
};

const getErrorMessageByErrorCode = (errorCode: string) => {
  // const error = errorCodes[errorCode];
  // if (error) return error;
  return errorCode;
};

const getSingleErrorMessage = (message: string) => {
  /*resultMessage = "KEY:(someKey),VALUE:(someValue)"*/
  if (uiHelper.errorCodeHasKey(message)) {
    // const errorCode = uiHelper.getErrorCodeValue(message);
    //Full Message and will be translated in Error-Message Alert
    // error = errorCodes[errorCode];
    // if (error)
    return message;
  } else {
    /*resultMessage = errorCode*/
    // error = errorCodes[message];
    // if (error) return error;
    return message;
  }
  return "something_wrong";
};

const getErrorMessageList = (result: ApiResponse<any, any>) => {
  let errorMessage;

  if (result.status !== errorStatuses.OK) {
    // we check !result.data in case the status was not ok and was not (Bad request) then we don't have result.data, instead we have result.originalError
    // example (ServiceOperationCategoryForm) on edit action.
    if (!result.data || result.data.Message || typeof result.data === "string")
      errorMessage = getErrorMessage(result);
    else {
      errorMessage = [];
      result.data.map((errorCode: string) => {
        return errorMessage.push(getSingleErrorMessage(errorCode));
      });
    }
  }
  return errorMessage;
};

const errorHandler = {
  getErrorMessage,
  getErrorMessageByErrorCode,
  getErrorMessageList,
  getSingleErrorMessage,
};

export default errorHandler;
