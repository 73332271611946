import { useContext } from "react";
import { LocalizationContext } from "../../../common/context/localizationContext";
import AppCard from "../../../common/components/cards/AppCard";
import AppCardBody from "../../../common/components/cards/AppCardBody";
import strings from "../../../base/strings";
import ECardModel from "../../../interfaces/eCards/ECardModel";
import LabelText from "../../../common/components/Labels/LabelText";
import ECardBenefitCoverageModel from "../../../interfaces/eCards/ECardBenefitCoverageModel";
import LabelTitle from "../../../common/components/Labels/LabelTitle";
import SectionTitle from "../../../common/components/forms/SectionTitle";
import styleConstants from "../../../common/constants/styleConstants";

interface Props {
  eCard: ECardModel | null;
  eCardBenefitCoverage: ECardBenefitCoverageModel;
}
export default function ECardBenefitCoverageInfo({
  // eCard,
  eCardBenefitCoverage,
}: Props) {
  const { getName } = useContext(LocalizationContext);
  const MIN_WIDTH = 130;

  return (
    <>
      <AppCard style={{ paddingBottom: styleConstants.GAP_LARGE }}>
        <LabelTitle
          title={getName(eCardBenefitCoverage.InsuranceSubProductModel)}
        />
        <AppCardBody
          orientation="horizontal"
          hasTitle={false}
          minComponentWidth={MIN_WIDTH}
          contentAlignment={"start"}
          numColumns={3}
          numColumnsMobile={2}
        >
          <LabelText
            labelTitle={strings.MEDICAL_NETWORK}
            labelValue={getName(eCardBenefitCoverage?.ProviderGroupModel)}
            orientation="vertical"
            minWidth={MIN_WIDTH}
          />
        </AppCardBody>
        <AppCardBody
          orientation="horizontal"
          hasTitle={false}
          minComponentWidth={MIN_WIDTH}
          contentAlignment={"start"}
          numColumns={3}
          numColumnsMobile={2}
        >
          <LabelText
            labelTitle={strings.COPAYMENT}
            labelValue={
              (eCardBenefitCoverage?.CopaymentPercentage
                ? eCardBenefitCoverage?.CopaymentPercentage
                : 0) + "%"
            }
            orientation="vertical"
            minWidth={MIN_WIDTH}
          />
          <LabelText
            labelTitle={strings.CHRONIC}
            labelValue={
              (eCardBenefitCoverage?.ChronicCopaymentPercentage
                ? eCardBenefitCoverage?.ChronicCopaymentPercentage
                : 0) + "%"
            }
            orientation="vertical"
            minWidth={MIN_WIDTH}
          />
          <LabelText
            labelTitle={strings.PREEXISTING}
            labelValue={
              (eCardBenefitCoverage?.PreexistingCopaymentPercentage
                ? eCardBenefitCoverage?.PreexistingCopaymentPercentage
                : 0) + "%"
            }
            orientation="vertical"
            minWidth={MIN_WIDTH}
          />
        </AppCardBody>
        <SectionTitle title={""} overrideMargin />
      </AppCard>
    </>
  );
}
