import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const policyRevisions = {
  columns: [
    {
      field: "ID",
      header: strings.REVISION_ID,
      width: "100px",
    },
    {
      field: "RevisionNumber",
      header: strings.REVISION_NUMBER,
      width: "100px",
    },

    {
      field: "ClientModel",
      header: strings.POLICY_HOLDER,
      width: "100px",
    },
    {
      field: "UnderwritingYear",
      header: strings.UNDERWRITING_YEAR,
      width: "100px",
    },
  ],
  autoCompleteEndpoint: endpoints.POLICIES,
  autoCompleteSearchKey: "IDStartWith",
  customObjectDisplay: "ClientModel",
};

export default policyRevisions;
