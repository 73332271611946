import { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../common/context/localizationContext";
import AppCard from "../../../common/components/cards/AppCard";
import AppCardBody from "../../../common/components/cards/AppCardBody";
import AppCardImage from "../../../common/components/cards/AppCardImage";
import strings from "../../../base/strings";
import ECardModel from "../../../interfaces/eCards/ECardModel";
import { StackLayout } from "@progress/kendo-react-layout";
import LabelTitle from "../../../common/components/Labels/LabelTitle";
import PillNotification from "../../../common/components/buttons/PillNotification";
import { colorCategory } from "../../../common/base/colorCategory";
import LabelText from "../../../common/components/Labels/LabelText";
import AppStackLayout from "../../../common/components/stack/AppStackLayout";

interface Props {
  eCard: ECardModel | null;
  alignNotificationsWithImage?: boolean;
}
export default function ECardInsuredBadge({
  eCard,
  alignNotificationsWithImage = false,
}: Props) {
  const [insuredPicture, setInsuredPicture] = useState<Uint8Array | null>(null);
  const { getName } = useContext(LocalizationContext);
  const MIN_COMPONENT_WIDTH = 310;
  const MIN_WIDTH = 200;

  useEffect(() => {
    if (eCard) setInsuredPicture(eCard?.InsuredPicture);
    // eslint-disable-next-line
  }, [eCard]);

  return (
    <AppCard>
      <AppCardBody
        orientation={"horizontal"}
        hasTitle={false}
        minComponentWidth={MIN_COMPONENT_WIDTH}
        contentAlignment={"start"}
        numColumnsMobile={1}
        numColumns={2}
      >
        <StackLayout align={{ vertical: "middle" }} orientation="horizontal">
          <AppCardImage
            image={insuredPicture}
            altText={strings.INSURED_PICTURE}
            width="70px"
            height="70px"
          />
          {alignNotificationsWithImage && eCard?.IsECardActive && (
            <PillNotification
              text={strings.ACTIVE}
              notificationStatus={colorCategory.SUCCESS}
            />
          )}
          {alignNotificationsWithImage && eCard?.IsInsuredCancelled && (
            <PillNotification
              text={strings.CANCELLED}
              notificationStatus={colorCategory.ERROR}
            />
          )}
        </StackLayout>
        <StackLayout orientation="vertical" align={{ vertical: "middle" }}>
          <AppStackLayout
            orientation="horizontal"
            switchOnMobile
            align={{ horizontal: "start" }}
          >
            <LabelTitle
              title={getName(eCard?.InsuredPersonInformationModel)}
              isLargeTitle={true}
            />
            {!alignNotificationsWithImage && eCard?.IsECardActive && (
              <PillNotification
                text={strings.ACTIVE}
                notificationStatus={colorCategory.SUCCESS}
              />
            )}
            {!alignNotificationsWithImage && eCard?.IsInsuredCancelled && (
              <PillNotification
                text={strings.CANCELLED}
                notificationStatus={colorCategory.ERROR}
              />
            )}
            {/* </div> */}
          </AppStackLayout>
          <LabelText
            labelTitle={strings.CARD_NUMBER}
            labelValue={eCard?.CardNumber}
            orientation="vertical"
            minWidth={MIN_WIDTH}
          />

          {/* <LabelText
            labelTitle={strings.DATE_OF_BIRTH}
            labelValue={eCard?.DateOfBirth}
            orientation="horizontal"
            minWidth={0}
          /> */}
        </StackLayout>
      </AppCardBody>
    </AppCard>
  );
}
