import { CSSProperties } from "react";
import {
  ExpansionPanel,
  ExpansionPanelContent,
  ExpansionPanelProps,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";

interface Props extends ExpansionPanelProps {
  revealStyle?: CSSProperties;
  overrideTextTransformation?: boolean;
}
export default function AppExpansionPanel({
  id,
  title,
  subtitle,
  expanded,
  children,
  onAction,
  revealStyle,
  overrideTextTransformation,
  ...rest
}: Props) {
  return (
    <ExpansionPanel
      title={title}
      subtitle={subtitle}
      expanded={expanded}
      tabIndex={0}
      key={id}
      onAction={onAction}
      className={
        "app-expansion-panel" +
        (overrideTextTransformation ? " k-non-transform" : "")
      }
      {...rest}
    >
      <Reveal
        transitionEnterDuration={150}
        transitionExitDuration={150}
        style={revealStyle || {}}
      >
        {expanded && (
          <ExpansionPanelContent>
            {/* wrap the children inside a div and stopPropagation in order to override (Enter + Space) Keyboard shortcuts from expansion panel */}
            <div onKeyDown={(e) => e.stopPropagation()}>{children}</div>
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
}
