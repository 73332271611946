import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.PVP;

const route_download_provider_payment_voucher_report =
  "/download-provider-payment-voucher-report";
const route_provider_payment_vouchers = "/provider-payment-vouchers";
const route_provider_payment_voucher_groups =
  "/provider-payment-voucher-groups";
const act_create_provider_payment_voucher_group =
  "/create-provider-payment-voucher-group";
const route_provider_payment_voucher_group = "/provider-payment-voucher-group";
const act_delete = "/delete";
const act_checkin_group = "/checkin-group";
const act_checkout_group = "/checkout-group";
const act_submit_group = "/submit-group";
const act_rollback_group = "/rollback-group";
const act_recalculate_group = "/recalculate-group";
const act_unaudit_group = "/unaudit-group";
const act_create_audit_pvp_group_task = "/create-audit-pvp-group-task";
const act_detach_pvp_group_task = "/detach-pvp-group-task";
const act_update_group_description = "/update-group-description";
const act_create_recalculate_pvp_group_syndicate_share_task =
  "/create-recalculate-pvp-group-syndicate-share-task";
const act_create_apply_penalty_for_pvp_group_task =
  "/create-apply-penalty-for-pvp-group-task";
const act_create_remove_penalty_from_pvp_group_task =
  "/create-remove-penalty-from-pvp-group-task";
const act_create_provider_payment_voucher = "/create-provider-payment-voucher";
const route_provider_payment_voucher = "/provider-payment-voucher";
const act_checkin = "/checkin";
const act_checkout = "/checkout";
const act_recalculate = "/recalculate";
const act_audit_provider_payment_voucher = "/audit-provider-payment-voucher";
const act_un_audit_provider_payment_voucher =
  "/unaudit-provider-payment-voucher";
const route_claim_invoice = "/claim-invoice";
const act_remove_claim_invoice = "/remove-claim-invoice";
const act_reset_provider_payment_voucher_as_settled =
  "/reset-provider-payment-voucher-as-settled";
const act_reset_provider_payment_voucher_as_collected =
  "/reset-provider-payment-voucher-as-collected";
const act_update_provider_payment_voucher_bank_account =
  "/update-provider-payment-voucher-bank-account";
const act_add_claim_invoices = "/add-claim-invoices";
const act_set_custom_discount = "/set-custom-discount";
const act_remove_custom_discount = "/remove-custom-discount";
const act_set_early_payment_discount = "/set-early-payment-discount";
const act_remove_early_payment_discount = "/remove-early-payment-discount";
const act_set_expedite_payment = "/set-expedite-payment";
const act_unset_expedite_payment = "/unset-expedite-payment";
const act_remove_pvp_penalty = "/remove-pvp-penalty";
const act_apply_pvp_penalty = "/apply-pvp_penalty";
const route_pvp_taxes = "/pvp-taxes";
const act_change_group = "/change-group";
const act_remove_provider_payment_voucher = "/remove-provider-payment-voucher";
const act_add_provider_payment_vouchers = "/add-provider-payment-vouchers";
const act_provider_payment_voucher_comment =
  "/provider-payment-voucher-comment";
const act_download_pvp_group_pvp_list_report =
  "/download-pvp-group-pvp-list-report";
const act_download_pvp_group_pvp_payments_report =
  "/download-pvp-group-pvp-payments-report";
const act_download_pvp_state_details_report =
  "/download-pvp-state-details-report";
const act_download_pvp_group_pvp_penalties_report =
  "/download-pvp-group-pvp-penalties-report";
const act_download_pvp_group_pvp_unverified_bank_accounts_report =
  "/download-pvp-group-pvp-unverified-bank-accounts-report";
const act_download_pvp_group_pvp_syndicate_report =
  "/download-pvp-group-pvp-syndicate-report";
const act_download_pvp_group_pvp_syndicate_by_sector_type_report =
  "/download-pvp-group-pvp-syndicate-by-sector-type-report";
const act_download_pvp_group_pvp_discount_report =
  "/download-pvp-group-pvp-discount-report";
const act_download_pvp_group_pvp_applied_discount_report =
  "/download-pvp-group-pvp-applied-discount-report";
const act_download_pvp_group_pvp_excluded_provider_discount_report =
  "/download-pvp-group-pvp-excluded-provider-discount-report";
const act_download_pvp_group_pvp_discounts_to_import_report =
  "/download-pvp-group-pvp-discounts-to-import-report";
const act_download_pvp_group_pvp_special_discount_on_requested_amount_report =
  "/download-pvp-group-special-discount-on-requested-amount-report";
const act_download_pvp_details_report = "/download-pvp-details-report";
const act_download_pvp_audit_report = "/download-pvp-audit-report";
const act_download_pvp_per_insurance_company_report =
  "/download-pvp-per-insurance-company-report";
const act_download_pvp_group_provider_payment_report =
  "/download-pvp-group-provider-payment-report";
const act_import_special_discounts = "/import-special-discounts";
const act_commit_special_discount_workspace =
  "/commit-speical-discount-workspace";
const route_payment_voucher_special_discounts =
  "/payment-voucher-special-discounts";
const route_payment_voucher_special_discount =
  "/payment-voucher-special-discount";
const act_create_payment_voucher_special_discount =
  "/create-payment-voucher-special-discount";
const act_update_payment_voucher_special_discount =
  "/update-payment-voucher-special-discount";
const act_download_pending_pvp_missing_payment_info_report =
  "/download-pending-pvp-missing-payment-info-report";
const act_pending_provider_payments_count = "/pending-provider-payments-count";
const act_generate_recalculate_pvp_task = "/generate-recalculate-pvp-task";
const act_detach_pvp_task = "/detach-pvp-task";
const act_generate_audit_pvp_task = "/generate-audit-pvp-task";
const route_download_pvp_balance_report = "/download-pvp-balance-report";

const getPVPs = (providerID, criteria, paging) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({ ...paging, ...criteria, ProviderID: providerID }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadProviderPaymentVoucherReport = (pvpID, criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_payment_voucher_report + "/" + pvpID,
    criteria,
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      // "X-HTTP-Method-Override": "GET",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderPaymentVouchers = (criteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_payment_vouchers,
    JSON.stringify({ ...paging, ...criteria }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderPaymentVoucherGroups = (criteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_payment_voucher_groups,
    JSON.stringify({ ...paging, ...criteria }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const createProviderPaymentVoucherGroup = (criteria) => {
  return apiClient.post(
    endpoint + act_create_provider_payment_voucher_group,
    JSON.stringify({ ...criteria })
  );
};

const getProviderPaymentVoucherGroup = (providerPaymentVoucherGroupID) =>
  apiClient.get(
    endpoint +
      route_provider_payment_voucher_group +
      "/" +
      providerPaymentVoucherGroupID
  );

const deleteProviderPaymentVoucherGroup = (providerPaymentVoucherGroupID) => {
  return apiClient.delete(
    endpoint + "/" + providerPaymentVoucherGroupID + act_delete
  );
};

const checkinProviderPaymentVoucherGroup = (providerPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherGroupID + act_checkin_group
  );
};

const checkoutProviderPaymentVoucherGroup = (providerPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherGroupID + act_checkout_group
  );
};

const submitProviderPaymentVoucherGroup = (providerPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherGroupID + act_submit_group
  );
};

const rollbackProviderPaymentVoucherGroup = (providerPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherGroupID + act_rollback_group
  );
};

const recalculateProviderPaymentVoucherGroup = (
  providerPaymentVoucherGroupID
) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherGroupID + act_recalculate_group
  );
};

const unauditProviderPaymentVoucherGroup = (providerPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherGroupID + act_unaudit_group
  );
};

const createAuditPVPGroupTask = (providerPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherGroupID +
      act_create_audit_pvp_group_task
  );
};

const detachProviderPaymentVoucherGroupTask = (
  providerPaymentVoucherGroupID
) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherGroupID + act_detach_pvp_group_task
  );
};

const updateProviderPaymentVoucherGroupDescription = (
  providerPaymentVoucherGroupID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherGroupID +
      act_update_group_description,
    JSON.stringify({ ...criteria })
  );
};

const createRecalculatePVPGroupSyndicateShareTask = (
  providerPaymentVoucherGroupID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherGroupID +
      act_create_recalculate_pvp_group_syndicate_share_task
  );
};

const createApplyPenaltyForProviderPaymentVoucherGroupTask = (
  providerPaymentVoucherGroupID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherGroupID +
      act_create_apply_penalty_for_pvp_group_task,
    JSON.stringify({ ...criteria })
  );
};

const createRemovePenaltyFromProviderPaymentVoucherGroupTask = (
  providerPaymentVoucherGroupID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherGroupID +
      act_create_remove_penalty_from_pvp_group_task
  );
};

const createProviderPaymentVoucher = (criteria) => {
  return apiClient.post(
    endpoint + act_create_provider_payment_voucher,
    JSON.stringify({ ...criteria })
  );
};

const getProviderPaymentVoucher = (providerPaymentVoucherID) =>
  apiClient.get(
    endpoint + route_provider_payment_voucher + "/" + providerPaymentVoucherID
  );

const deleteProviderPaymentVoucher = (providerPaymentVoucherID) => {
  return apiClient.delete(
    endpoint +
      route_provider_payment_voucher +
      "/" +
      providerPaymentVoucherID +
      act_delete
  );
};

const checkinProviderPaymentVoucher = (providerPaymentVoucherID) => {
  return apiClient.put(endpoint + "/" + providerPaymentVoucherID + act_checkin);
};

const checkoutProviderPaymentVoucher = (providerPaymentVoucherID) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherID + act_checkout
  );
};

const recalculateProviderPaymentVoucher = (providerPaymentVoucherID) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherID + act_recalculate
  );
};

const auditProviderPaymentVoucher = (providerPaymentVoucherID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherID +
      act_audit_provider_payment_voucher
  );
};

const unAuditProviderPaymentVoucher = (providerPaymentVoucherID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherID +
      act_un_audit_provider_payment_voucher
  );
};

const removeProviderPaymentVoucherClaimInvoice = (
  providerPaymentVoucherID,
  claimInvoiceID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_remove_claim_invoice
  );
};

const resetProviderPaymentVoucherAsSettled = (providerPaymentVoucherID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherID +
      act_reset_provider_payment_voucher_as_settled
  );
};

const resetProviderPaymentVoucherAsCollected = (providerPaymentVoucherID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherID +
      act_reset_provider_payment_voucher_as_collected
  );
};

const updateProviderPaymentVoucherBankAccount = (providerPaymentVoucherID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherID +
      act_update_provider_payment_voucher_bank_account
  );
};

const addClaimInvoices = (providerPaymentVoucherID, criteria) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherID + act_add_claim_invoices,
    JSON.stringify({
      ...criteria,
    })
  );
};

const setProviderPaymentVoucherCustomDiscount = (
  providerPaymentVoucherID,
  criteria
) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherID + act_set_custom_discount,
    JSON.stringify({ ...criteria })
  );
};

const removeProviderPaymentVoucherCustomDiscount = (
  providerPaymentVoucherID
) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherID + act_remove_custom_discount
  );
};

const setPVPEarlyPaymentDiscount = (providerPaymentVoucherID, criteria) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherID + act_set_early_payment_discount,
    JSON.stringify({ ...criteria })
  );
};

const removePVPEarlyPaymentDiscount = (providerPaymentVoucherID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherID +
      act_remove_early_payment_discount
  );
};

const setProviderPaymentVoucherExpeditePayment = (
  providerPaymentVoucherID,
  criteria
) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherID + act_set_expedite_payment,
    JSON.stringify({ ...criteria })
  );
};

const unsetProviderPaymentVoucherExpeditePayment = (
  providerPaymentVoucherID
) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherID + act_unset_expedite_payment
  );
};

const removePenaltyOnProviderPaymentVoucher = (providerPaymentVoucherID) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherID + act_remove_pvp_penalty
  );
};

const applyPenaltyOnProviderPaymentVoucher = (
  providerPaymentVoucherID,
  criteria
) => {
  return apiClient.put(
    endpoint + "/" + providerPaymentVoucherID + act_apply_pvp_penalty,
    JSON.stringify({ ...criteria })
  );
};

const getProviderPaymentVoucherTaxes = (providerPaymentVoucherID) => {
  return apiClient.get(
    endpoint + "/" + providerPaymentVoucherID + route_pvp_taxes
  );
};

const updateProviderPaymentVoucherPVPGroup = (
  providerPaymentVoucherID,
  providerPaymentVoucherGroupID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherID +
      route_provider_payment_voucher_group +
      "/" +
      providerPaymentVoucherGroupID +
      act_change_group
  );
};

const removeProviderPaymentVoucherFromPVPGroup = (
  providerPaymentVoucherGroupID,
  providerPaymentVoucherID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherGroupID +
      route_provider_payment_voucher +
      "/" +
      providerPaymentVoucherID +
      act_remove_provider_payment_voucher
  );
};

const addProviderPaymentVouchersToPVPGroup = (
  providerPaymentVoucherGroupID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerPaymentVoucherGroupID +
      act_add_provider_payment_vouchers,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addProviderPaymentVoucherComment = (
  providerPaymentVoucherID,
  commentID,
  additionalInfo
) => {
  return apiClient.post(
    endpoint +
      "/" +
      providerPaymentVoucherID +
      act_provider_payment_voucher_comment,
    JSON.stringify({ CommentID: commentID, AdditionalInfo: additionalInfo })
  );
};

const downloadPVPGroupPVPListReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_pvp_list_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPPaymentsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_pvp_payments_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPStateDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_state_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPPenaltiesReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_pvp_penalties_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPUnverifiedBankAccountsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_pvp_unverified_bank_accounts_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPSyndicateReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_pvp_syndicate_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPSyndicateBySectorTypeReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_pvp_syndicate_by_sector_type_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPDiscountReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_pvp_discount_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPAppliedDiscountReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_pvp_applied_discount_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPExcludedProviderDiscountReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_pvp_excluded_provider_discount_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPDiscountsToImportReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_pvp_discounts_to_import_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupPVPSpecialDiscountOnRequestedAmountReport = (
  criteria
) => {
  return apiClient.post(
    endpoint +
      act_download_pvp_group_pvp_special_discount_on_requested_amount_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPBalanceReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_pvp_balance_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPAuditReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_audit_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPPerInsuranceCompanyReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_per_insurance_company_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVPGroupProviderPaymentReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvp_group_provider_payment_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importSpecialDiscounts = (formData, onUploadProgress) => {
  return apiClient.post(endpoint + act_import_special_discounts, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progress) =>
      onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
  });
};

const commitSpecialDiscountWorkspace = (
  providerPaymentVoucherGroupID,
  acceptedSpecialDiscounts
) => {
  return apiClient.post(
    endpoint +
      "/" +
      providerPaymentVoucherGroupID +
      act_commit_special_discount_workspace,
    JSON.stringify({ AcceptedSepcialDiscounts: acceptedSpecialDiscounts })
  );
};

const getPaymentVoucherSpecialDiscounts = (providerPaymentVoucherGroupID) => {
  return apiClient.get(
    endpoint +
      "/" +
      providerPaymentVoucherGroupID +
      route_payment_voucher_special_discounts
  );
};

const deletePaymentVoucherSpecialDiscount = (
  paymentVoucherSpecialDiscountID
) => {
  return apiClient.delete(
    endpoint +
      route_payment_voucher_special_discount +
      "/" +
      paymentVoucherSpecialDiscountID +
      act_delete
  );
};

const createPaymentVoucherSpecialDiscount = (criteria) => {
  return apiClient.post(
    endpoint + act_create_payment_voucher_special_discount,
    JSON.stringify({ ...criteria })
  );
};

const updatePaymentVoucherSpecialDiscount = (
  paymentVoucherSpecialDiscountID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      route_payment_voucher_special_discount +
      "/" +
      paymentVoucherSpecialDiscountID +
      act_update_payment_voucher_special_discount,
    JSON.stringify({ ...criteria })
  );
};

const downloadPendingPVPMissingPaymentInfoReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pending_pvp_missing_payment_info_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPendingProviderPaymentsCount = () => {
  return apiClient.get(endpoint + act_pending_provider_payments_count);
};

const generateRecalculateProviderPaymentVoucherTask = (pvpID) => {
  return apiClient.put(
    endpoint + "/" + pvpID + act_generate_recalculate_pvp_task
  );
};

const detachPVPTask = (pvpID) => {
  return apiClient.put(endpoint + "/" + pvpID + act_detach_pvp_task);
};

const generateAuditProviderPaymentVoucherTask = (pvpID) => {
  return apiClient.put(endpoint + "/" + pvpID + act_generate_audit_pvp_task);
};

const pvpApi = {
  getPVPs,
  downloadProviderPaymentVoucherReport,
  getProviderPaymentVouchers,
  getProviderPaymentVoucherGroups,
  createProviderPaymentVoucherGroup,
  getProviderPaymentVoucherGroup,
  deleteProviderPaymentVoucherGroup,
  checkinProviderPaymentVoucherGroup,
  checkoutProviderPaymentVoucherGroup,
  submitProviderPaymentVoucherGroup,
  rollbackProviderPaymentVoucherGroup,
  recalculateProviderPaymentVoucherGroup,
  unauditProviderPaymentVoucherGroup,
  createAuditPVPGroupTask,
  detachProviderPaymentVoucherGroupTask,
  updateProviderPaymentVoucherGroupDescription,
  createRecalculatePVPGroupSyndicateShareTask,
  createApplyPenaltyForProviderPaymentVoucherGroupTask,
  createRemovePenaltyFromProviderPaymentVoucherGroupTask,
  createProviderPaymentVoucher,
  getProviderPaymentVoucher,
  deleteProviderPaymentVoucher,
  checkinProviderPaymentVoucher,
  checkoutProviderPaymentVoucher,
  recalculateProviderPaymentVoucher,
  auditProviderPaymentVoucher,
  unAuditProviderPaymentVoucher,
  removeProviderPaymentVoucherClaimInvoice,
  resetProviderPaymentVoucherAsSettled,
  resetProviderPaymentVoucherAsCollected,
  updateProviderPaymentVoucherBankAccount,
  addClaimInvoices,
  setProviderPaymentVoucherCustomDiscount,
  removeProviderPaymentVoucherCustomDiscount,
  setPVPEarlyPaymentDiscount,
  removePVPEarlyPaymentDiscount,
  setProviderPaymentVoucherExpeditePayment,
  unsetProviderPaymentVoucherExpeditePayment,
  removePenaltyOnProviderPaymentVoucher,
  applyPenaltyOnProviderPaymentVoucher,
  getProviderPaymentVoucherTaxes,
  updateProviderPaymentVoucherPVPGroup,
  removeProviderPaymentVoucherFromPVPGroup,
  addProviderPaymentVouchersToPVPGroup,
  addProviderPaymentVoucherComment,
  downloadPVPGroupPVPListReport,
  downloadPVPGroupPVPPaymentsReport,
  downloadPVPGroupPVPStateDetailsReport,
  downloadPVPGroupPVPPenaltiesReport,
  downloadPVPGroupPVPUnverifiedBankAccountsReport,
  downloadPVPGroupPVPSyndicateReport,
  downloadPVPGroupPVPSyndicateBySectorTypeReport,
  downloadPVPGroupPVPDiscountReport,
  downloadPVPGroupPVPAppliedDiscountReport,
  downloadPVPGroupPVPExcludedProviderDiscountReport,
  downloadPVPGroupPVPDiscountsToImportReport,
  downloadPVPGroupPVPSpecialDiscountOnRequestedAmountReport,
  downloadPVPDetailsReport,
  downloadPVPBalanceReport,
  downloadPVPAuditReport,
  downloadPVPPerInsuranceCompanyReport,
  downloadPVPGroupProviderPaymentReport,
  importSpecialDiscounts,
  commitSpecialDiscountWorkspace,
  getPaymentVoucherSpecialDiscounts,
  deletePaymentVoucherSpecialDiscount,
  createPaymentVoucherSpecialDiscount,
  updatePaymentVoucherSpecialDiscount,
  downloadPendingPVPMissingPaymentInfoReport,
  getPendingProviderPaymentsCount,
  generateRecalculateProviderPaymentVoucherTask,
  detachPVPTask,
  generateAuditProviderPaymentVoucherTask,
};

export default pvpApi;
