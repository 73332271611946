import React, { useContext, useEffect, useState } from "react";
import InsuredMedicalNoteModel from "../../../interfaces/medicalFiles/medicalConditions/InsuredMedicalNoteModel";
import CommentModel from "../../../common/interfaces/comments/CommentModel";
import insuredMedicalNotesHelper from "../../medicalFiles/base/insuredMedicalNotesHelper";
import AppCard from "../../../common/components/cards/AppCard";

import AppCardBody from "../../../common/components/cards/AppCardBody";
import { LocalizationContext } from "../../../common/context/localizationContext";
import LabelText from "../../../common/components/Labels/LabelText";
import SectionTitle from "../../../common/components/forms/SectionTitle";
import LabelTitle from "../../../common/components/Labels/LabelTitle";

interface Props {
  medicalNotes: InsuredMedicalNoteModel[];
}
export default function ECardInsuredMedicalNotesPanel({ medicalNotes }: Props) {
  const { getName } = useContext(LocalizationContext);

  const [medicalNoteTypes, setMedicalNoteTypes] = useState<
    {
      ID: number;
      CommentModel: CommentModel;
    }[]
  >([]);

  useEffect(() => {
    medicalNotes &&
      setMedicalNoteTypes(
        insuredMedicalNotesHelper.populateMedicalNoteTypes(medicalNotes)
      );
  }, [medicalNotes]);

  return (
    <>
      {React.Children.toArray(
        medicalNoteTypes.map((noteType, i) => {
          return (
            <AppCard
              style={{
                width: "500px",
                // marginBottom: "1em",
                padding: "1em",
                paddingTop: 0,
              }}
            >
              {/* <AppCardHeader title={getName(noteType.CommentModel)} /> */}
              <AppCardBody
                hasTitle={false}
                minComponentWidth={350}
                orientation="vertical"
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <LabelTitle title={getName(noteType.CommentModel) + " :"} />
                {medicalNotes &&
                  medicalNotes.length > 0 &&
                  React.Children.toArray(
                    medicalNotes.map(
                      (insuredMedicalNote: InsuredMedicalNoteModel, i) => {
                        if (insuredMedicalNote.CommentModel.ID === noteType.ID)
                          return (
                            <LabelText
                              labelTitle={i + 1 + ""}
                              labelValue={insuredMedicalNote.CommentText}
                              minWidth={25}
                              minValueWidth={250}
                              orientation="horizontal"
                            />
                          );
                        return null;
                      }
                    )
                  )}
                <SectionTitle title={""} overrideMargin />
              </AppCardBody>
            </AppCard>
          );
        })
      )}
    </>
  );
}
