import ECardModel from "../../../interfaces/eCards/ECardModel";
import React, { Dispatch, SetStateAction } from "react";
import ECardProductCoverageInfo from "../cards/ECardProductCoverageInfo";
import AppCard from "../../../common/components/cards/AppCard";

interface Props {
  eCard: ECardModel | null;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function ECardCoveragesPanel({
  eCard,
  loading,
  setLoading,
}: Props) {
  return (
    <AppCard>
      {eCard?.ECardProductCoverageModelList &&
      eCard?.ECardProductCoverageModelList.length > 0 ? (
        eCard?.ECardProductCoverageModelList.map((item, i) => (
          <React.Fragment key={i}>
            <ECardProductCoverageInfo
              eCard={eCard}
              eCardProductCoverage={item}
              loading={loading}
              setLoading={setLoading}
            />
          </React.Fragment>
        ))
      ) : (
        <></>
      )}
    </AppCard>
  );
}
