import { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../common/context/localizationContext";
import LoadingPanel from "../../common/components/general/LoadingPanel";
import AppStackLayout from "../../common/components/stack/AppStackLayout";
import Alert from "../../common/components/general/Alert";
import errorHandler from "../../common/utility/errorHandler";
import { alertTypes } from "../../common/components/base/alertTypes";
import MainAppBar from "../../common/layout/MainAppBar";
import images from "../../base/images";
import useBreakpoint from "../../common/hooks/useBreakpoint";
import breakpointKeys from "../../common/constants/breakpointKeys";
import strings from "../../base/strings";
import { useLocation } from "react-router-dom";
import ContentContainer from "../../common/components/container/ContentContainer";
import ELanguageCodes from "../../common/base/languageCodes";
import AppActionBar from "../../common/components/actionBar/AppActionBar";
import AppDropDownButton from "../../common/components/buttons/AppDropDownButton";
import icons from "../../common/constants/icons";
import cardsApi from "../../api/cards";
import ECardModel from "../../interfaces/eCards/ECardModel";
import AppExpansionPanel from "../../common/components/expansionPanel/AppExpansionPanel";
import ECardNotesPanel from "../../controls/eCards/panels/ECardNotesPanel";
import ECardCoveragesPanel from "../../controls/eCards/panels/ECardCoveragesPanel";
import ECardInsuredBadge from "../../controls/eCards/cards/ECardInsuredBadge";
import ECardInsuredInfo from "../../controls/eCards/cards/ECardInsuredInfo";
import ECardInsuredMedicalNotesPanel from "../../controls/eCards/panels/ECardInsuredMedicalNotesPanel";
import AppScrollbar from "../../common/components/scrollbar/AppScrollbar";

interface Props {}

const ECardPage = ({}: Props) => {
  const { translate, isRTL, updateLanguage } = useContext(LocalizationContext);

  const location = useLocation();
  const pageCriteria: any = location?.state; //{CARD_NUMBER: '826694'}

  let cardNumber: string | null =
    pageCriteria && (pageCriteria as { [key: string]: any })
      ? pageCriteria["CARD_NUMBER"]
      : null;

  let eCardVisa: string | null =
    pageCriteria && (pageCriteria as { [key: string]: any })
      ? pageCriteria["ETPA_VISA"]
      : null;

  // const { id: cardNumber } = useParams();

  // useDocumentTitle(strings.E_CARD, cardNumber);

  const MIN_CARD_NOT_WIDTH = 260;

  const ARABIC_LANGUAGE: {
    Name: string;
    Code: string;
    Localization: string;
    rtlActive: boolean;
  } = {
    ...ELanguageCodes.ARABIC,
  };

  const ENGLISH_LANGUAGE: {
    Name: string;
    Code: string;
    Localization: string;
    rtlActive: boolean;
  } = {
    ...ELanguageCodes.ENGLISH,
  };

  const [languages] = useState<
    {
      Name: string;
      Code: string;
      Localization: string;
      rtlActive: boolean;
    }[]
  >([ENGLISH_LANGUAGE, ARABIC_LANGUAGE]);

  const [selectedLanguage, setSelectedLanguage] = useState<{
    Name: string;
    Code: string;
    Localization: string;
    rtlActive: boolean;
  }>(isRTL ? ARABIC_LANGUAGE : ENGLISH_LANGUAGE);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");

  const [eCard, setECard] = useState<ECardModel | null>(null);

  const [isCoveragesExpanded, setIsCoveragesExpanded] =
    useState<boolean>(false);
  const [isMedicalNotesExpanded, setIsMedicalNotesExpanded] =
    useState<boolean>(false);
  const [isCardNotesExpanded, setIsCardNotesExpanded] =
    useState<boolean>(false);

  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    loadECard();
  }, [cardNumber]);

  const loadECard = async () => {
    setLoading(true);
    const response = await cardsApi.getECards(cardNumber, eCardVisa);
    setLoading(false);
    if (!response.ok) setErrorMessage(errorHandler.getErrorMessage(response));
    else {
      setECard(response.data);
    }
  };

  return (
    <>
      <MainAppBar
        handleClick={() => {}}
        logo={images.APPLICATION_TEXT_LOGO}
        showLanguage={false}
        hideDrawerButton={true}
        hideUserContextMenu={true}
        // hideUserPopupMenu={true}
        // hideUsername={false}
        ignoreNotifications={true}
      />
      <AppStackLayout className="external-container">
        <ContentContainer>
          <AppStackLayout style={{ height: 600 }}>
            <AppScrollbar>
              {eCard && (
                <AppStackLayout className={"form-container"}>
                  <div>
                    <AppStackLayout
                      align={{
                        horizontal: isRTL ? "start" : "end",
                      }}
                    >
                      <AppActionBar>
                        <AppDropDownButton
                          className="button"
                          icon={icons.ARROW_CHEVRON_DOWN}
                          themeColor={"primary"}
                          text={selectedLanguage.Name}
                          textField="Name"
                          items={languages}
                          onItemClick={(e) => {
                            setSelectedLanguage(
                              e.item.Code === "ar"
                                ? ARABIC_LANGUAGE
                                : ENGLISH_LANGUAGE
                            );

                            updateLanguage(e.item.Code);
                          }}
                        />
                      </AppActionBar>
                    </AppStackLayout>
                    <ECardInsuredBadge
                      eCard={eCard}
                      alignNotificationsWithImage={
                        breakpoint === breakpointKeys.MOBILE
                      }
                    />
                    <ECardInsuredInfo eCard={eCard} />
                    <AppStackLayout orientation="vertical">
                      <AppExpansionPanel
                        title={translate(strings.COVERAGES)}
                        expanded={isCoveragesExpanded}
                        onAction={(e) => {
                          setIsCoveragesExpanded(!e.expanded);
                        }}
                      >
                        <ECardCoveragesPanel
                          eCard={eCard}
                          loading={loading}
                          setLoading={setLoading}
                        />
                      </AppExpansionPanel>
                      {eCard?.InsuredMedicalNoteModelList && (
                        <AppExpansionPanel
                          title={translate(strings.MEDICAL_NOTES)}
                          expanded={isMedicalNotesExpanded}
                          onAction={(e) => {
                            setIsMedicalNotesExpanded(!e.expanded);
                          }}
                        >
                          <ECardInsuredMedicalNotesPanel
                            medicalNotes={eCard?.InsuredMedicalNoteModelList}
                          />
                        </AppExpansionPanel>
                      )}
                      {eCard?.ECardNoteModelList && (
                        <AppExpansionPanel
                          title={translate(strings.CARD_NOTES)}
                          expanded={isCardNotesExpanded}
                          onAction={(e) => {
                            setIsCardNotesExpanded(!e.expanded);
                          }}
                        >
                          <ECardNotesPanel
                            cardNotes={eCard?.ECardNoteModelList}
                            minWidth={
                              breakpoint === breakpointKeys.MOBILE
                                ? MIN_CARD_NOT_WIDTH
                                : undefined
                            }
                          />
                        </AppExpansionPanel>
                      )}
                    </AppStackLayout>
                  </div>
                </AppStackLayout>
              )}
            </AppScrollbar>
          </AppStackLayout>
        </ContentContainer>
      </AppStackLayout>
      <div
        style={{
          height: "29px",
          display: "flex",
          marginTop: "1px",
          flexDirection: "row-reverse",
          paddingInlineEnd: "30px",
        }}
      >
        <img src={images.POWERED_BY_TRIAMETIS} alt="Powered By Triametis" />
      </div>
      {loading && <LoadingPanel />}
      {errorMessage && (
        <Alert message={errorMessage} setMessage={setErrorMessage} />
      )}
      {alertMessage && (
        <Alert
          message={alertMessage}
          setMessage={setAlertMessage}
          type={alertTypes.INFO}
        />
      )}
    </>
  );
};

export default ECardPage;
