import InsuredMedicalNoteModel from "./../../../interfaces/medicalFiles/medicalConditions/InsuredMedicalNoteModel";
import CommentModel from "../../../common/interfaces/comments/CommentModel";

const populateMedicalNoteTypes = (medicalNotes: InsuredMedicalNoteModel[]) => {
  const noteTypes: { ID: number; CommentModel: CommentModel }[] = [];

  if (medicalNotes && medicalNotes.length > 0) {
    for (const note of medicalNotes) {
      if (
        !noteTypes.find((element) => {
          return element.ID === note.CommentModel.ID;
        })
      ) {
        noteTypes.push({
          ID: note.CommentModel.ID,
          CommentModel: note.CommentModel,
        });
      }
    }
  }

  return noteTypes;
};

const insuredMedicalNotesHelper = {
  populateMedicalNoteTypes,
};

export default insuredMedicalNotesHelper;
