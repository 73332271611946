import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppAutoComplete from "../autocomplete/AppAutoComplete";
import ServiceAutoComplete from "../../controls/services/base/ServiceAutoComplete";
import AppLinkedDropDowns from "../../common/components/dropDown/AppLinkedDropDowns";
import ServiceModel from "../../interfaces/services/ServiceModel";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppServicePricingServiceSearchFieldKeys = {
  ServiceID: "ServiceID",
};
export default function AppServicePricingServiceSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
  parentObjectID,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const [service, setService] = useState<ServiceModel | null>();
  const [searchBy, setSearchBy] = useState<any | undefined>({
    ID: 1,
    Name: translate(strings.ALL),
    Code: "",
  });

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleServiceChange = async (event: any) => {
    const value = event.value;
    if (!value) {
      setService(null);
      removeSearchFieldValue(AppServicePricingServiceSearchFieldKeys.ServiceID);
    } else {
      const searchValue: number = value.ID;
      setService(value);
      setSearchFieldValues(
        AppServicePricingServiceSearchFieldKeys.ServiceID,
        searchValue
      );
    }
  };

  const handleKeyChange = async (event: any) => {
    const value = event.value;
    removeSearchFieldValue(AppServicePricingServiceSearchFieldKeys.ServiceID);
    setService(null);
    await setSearchBy(undefined);
    if (value) await setSearchBy(value);
  };

  return (
    <span style={{ display: "inline-flex" }}>
      <AppLinkedDropDowns>
        <AppAutoComplete<any>
          className="search-component"
          dataList={[
            {
              ID: 1,
              Name: translate(strings.ALL_SERVICES),
              Code: "",
            },
            {
              ID: 2,
              Name: translate(strings.IN_SCHEME_ONLY),
              Code: strings.PRICING_SCHEME,
            },
          ]}
          columns={[
            {
              field: "Name",
              header: translate(strings.SEARCH_BY),
              width: "200",
            },
          ]}
          textFieldDisplay={"Name"}
          placeholder={translate(strings.SERVICE_SEARCH)}
          defaultValue={{
            ID: 1,
            Name: translate(strings.ALL),
            Code: "",
          }}
          onChange={handleKeyChange}
        />
        {searchBy && (
          <ServiceAutoComplete
            className="search-component"
            columns={[]}
            onChange={handleServiceChange}
            placeholder={translate(strings.SERVICE)}
            searchCriteria={
              searchBy.Code === strings.PRICING_SCHEME
                ? {
                    PricingSchemeID: parentObjectID,
                    NotOnlyDefaultPriced: true,
                  }
                : {}
            }
            dataList={[]}
            loadDataOnOpen
            value={service}
            searchKey="ServiceNameOrCode"
          />
        )}
      </AppLinkedDropDowns>
    </span>
  );
}
