import { Navigate, Route, Routes } from "react-router-dom";
import baseRoutes from "../../common/base/baseRoutes";
import ProtectedLogin from "../../common/routes/ProtectedLogin";
import { lazy, Suspense } from "react";
import routes from "../../base/routes";
import SuspenseComponent from "../../pages/general/SuspenseComponent";
import SearchMedicalNetworkPage from "../../pages/providers/SearchMedicalNetworkPage";
import CreateProviderWebRequestPage from "../../pages/providers/CreateProviderWebRequest";
import portals from "../../base/portals";
import ProtectedRoute from "../../common/routes/ProtectedRoute";
import UploadDigitalPrescriptionImagesPage from "../../pages/digitalPrescriptions/UploadDigitalPrescriptionImagesPage";
import ECardPage from "../../pages/Policies/ECardPage";

interface Props {}
export default function AppTPATenantRoutes({}: Props) {
  const InsuranceCompanyPortal = lazy(
    () => import("../insuranceCompany/InsuranceCompanyPortal")
  );
  const ErrorPage = lazy(() => import("../../pages/general/ErrorPage"));
  const AdminPortal = lazy(() => import("../admin/AdminPortal"));
  const ProviderPortal = lazy(() => import("../provider/ProviderPortal"));
  const ClientPortal = lazy(() => import("../client/ClientPortal"));
  const ReinsurerPortal = lazy(() => import("../reinsurer/ReinsurerPortal"));
  const Activation = lazy(
    () => import("../../pages/authentication/Activation")
  );
  const LogIn = lazy(() => import("../../pages/authentication/LogIn"));
  const ProviderApprovalQueryPage = lazy(
    () => import("../../pages/general/ProviderApprovalQueryPage")
  );

  const RoutePage = lazy(() => import("../../pages/general/RoutePage"));
  return (
    <Routes>
      <Route
        path={baseRoutes.LOGIN}
        element={
          <ProtectedLogin
            path={baseRoutes.LOGIN}
            component={LogIn}
            //   auth={auth}
          />
        }
      />
      <Route
        path={routes.ACTIVATE_ACCOUNT}
        element={
          <Suspense fallback={<SuspenseComponent />}>
            <Activation />
          </Suspense>
        }
      />
      <Route
        path={routes.SEARCH_MEDICAL_NETWORK}
        element={
          <Suspense fallback={<SuspenseComponent />}>
            <SearchMedicalNetworkPage />
          </Suspense>
        }
      />
      <Route
        path={routes.PROVIDER_WEB_REQUEST}
        element={
          <Suspense fallback={<SuspenseComponent />}>
            <CreateProviderWebRequestPage />
          </Suspense>
        }
      />
      <Route
        path={portals.INSURANCE_COMPANY_PORTAL + "/*"}
        element={
          <ProtectedRoute
            // path={portals.ADMIN_PORTAL}
            component={InsuranceCompanyPortal}
            //   auth={auth}
          />
        }
      />
      <Route
        path={portals.CLIENT_PORTAL + "/*"}
        element={
          <ProtectedRoute
            // path={portals.ADMIN_PORTAL}
            component={ClientPortal}
            //   auth={auth}
          />
        }
      />
      <Route
        path={portals.PROVIDER_PORTAL + "/*"}
        element={<ProtectedRoute component={ProviderPortal} />}
      />
      <Route
        path={portals.REINSURER_PORTAL + "/*"}
        element={<ProtectedRoute component={ReinsurerPortal} />}
      />
      <Route
        path={portals.ADMIN_PORTAL + "/*"}
        element={
          <ProtectedRoute
            // path={portals.ADMIN_PORTAL}
            component={AdminPortal}
            //   auth={auth}
          />
        }
      />
      <Route
        path={baseRoutes.HOME}
        element={<Navigate to={baseRoutes.LOGIN} />}
      />
      <Route
        path={baseRoutes.ERROR_PAGE}
        element={
          <Suspense fallback={<SuspenseComponent />}>
            <ErrorPage />
          </Suspense>
        }
      />
      <Route
        path={routes.PROVIDER_APPROVAL_QUERY_PAGE}
        element={
          <Suspense fallback={<SuspenseComponent />}>
            <ProviderApprovalQueryPage />
          </Suspense>
        }
      />
      {/* <Route
              path={routes.MENU}
              element={
                <ProtectedRoute //path={routes.MENU}
                  component={MenuTest}
                />
              }
            /> */}

      {/* <Route
              path={routes.LOGIN}
              element={(props: any) => {
                return user ? <LogIn {...props} /> : <Navigate to="/admin" />;
              }}
            /> */}

      <Route
        path={
          routes.DIGITAL_PRESCRIPTIONS +
          "/:id1" +
          routes.UPLOAD_DIGITAL_PRESCRIPTION_IMAGES +
          "/:id2"
        }
        element={
          <Suspense fallback={<SuspenseComponent />}>
            <UploadDigitalPrescriptionImagesPage />
          </Suspense>
        }
      />
      <Route
        // path={routes.E_CARD + "/:id"}
        path={routes.E_CARD}
        element={
          <Suspense fallback={<SuspenseComponent />}>
            <ECardPage />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<SuspenseComponent />}>
            <RoutePage />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
      {/** Always keep at the end*/}
    </Routes>
  );
}
