export enum errorStatuses {
  BAD_REQUEST = 400,
  AUTHENTICATION_ERROR = 401,
  FORBIDDEN = 403,
  CLIENT_ERROR = 404,
  TOKEN_EXPIRED = 432,
  ACCOUNT_SUSPENDED = 433,
  USER_IS_DISABLED = 434,
  SESSION_EXPIRED = 444,
  SERVER_ERROR = 500,
  OK = 200,
}
