import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.SERVICES;
const route_service_discount_scheme = "/service-discount-scheme";
const route_operation_category_scheme = "/operation-category-scheme";
const route_service_types = "/service-types";
const route_drug_manufacturers = "/drug-manufacturers";
const route_medicine_dosage_forms = "/medicine-dosage-forms";
const route_unit_conversion = "/unit-conversion";
const route_service_table_type_codes = "/service-table-type-codes";
const route_service_table_type = "/service-table-type";
const route_service_protocols = "/service-protocols";
const route_checkin_service = "/checkin-service";
const route_checkout_service = "/checkout-service";
const route_cancel_service = "/cancel-service";
const route_reinstate_service = "/reinstate-service";
const route_update_service_basic_information =
  "/update-service-basic-information";
const route_update_service_type = "/update-service-type";
const route_update_service_barcode = "/update-service-barcode";
const route_operation = "/is-operation";
const route_unavailable = "/is-unavailable";
const route_consultation = "/is-consultation";
const route_priced_on_dispensation = "/is-priced-on-dispensation";
const route_show_in_online_portal = "/show-in-online-portal";
const route_frequently_used = "/is-frequently-used";
const route_requires_tooth_number = "/requires-tooth-number";
const route_detail = "/is-detail";
const route_general_exclusions = "/is-general-exclusions";
const route_room_and_board = "/is-room-and-board";
const route_update_service_medicine_info = "/update-service-medicine-info";
const route_requires_results = "/requires-results";
const route_table_type_code = "/service-table-type-code";
const route_delete_table_type_code = "/delete-service-table-type-code";
const route_update_table_type_code = "/update-service-table-type-code";
const route_service_protocol = "/service-protocol";
const route_delete_service_protocol = "/delete-service-protocol";
const route_update_service_protocol = "/update-service-protocol";
const route_cancel_service_protocol = "/cancel-service-protocol";
const route_reinstate_service_protocol = "/reinstate-service-protocol";
const route_service_pricings = "/service-pricings";
const route_service_pricing = "/service-pricing";
const route_update_service_pricing = "/update-service-pricing";
const route_delete_service_pricing = "/delete-service-pricing";
const route_cancel_service_pricing = "/cancel-service-pricing";
const route_reinstate_service_pricing = "/reinstate-service-pricing";
const route_service_protocol_pricings = "/service-protocol-pricings";
const route_service_protocol_pricing = "/service-protocol-pricing";
const route_update_service_protocol_pricing =
  "/update-service-protocol-pricing";
const route_cancel_service_protocol_pricing =
  "/cancel-service-protocol-pricing";
const route_reinstate_service_protocol_pricing =
  "/reinstate-service-protocol-pricing";
const route_delete_service_protocol_pricing =
  "/delete-service-protocol-pricing";
const route_medicine_ingredients = "/medicine-ingredients";
const route_atc_ingredients = "/atc-ingredients";
const route_chemical_compounds = "/chemical-compounds";
const route_medicine_ingredient = "/medicine-ingredient";
const route_update_medicine_ingredient = "/update-medicine-ingredient";
const route_delete_medicine_ingredient = "/delete-medicine-ingredient";
const route_service_attachments = "/service-attachments";
const route_service_attachment = "/service-attachment";
const route_update_service_attachment = "/update-service-attachment";
const route_delete_service_attachment = "/delete-service-attachment";
const route_service_comments = "/service-comments";
const route_service_comment = "/service-comment";
const route_update_service_comment = "/update-service-comment";
const route_delete_service_comment = "/delete-service-comment";
const route_service_groups = "/service-groups";
const route_service_categories = "/service-categories";
const route_update_service_automatic_rejection =
  "/update-service-automatic-rejection";
const route_update_service_management_configuration =
  "/update-service-management-configuration";
const route_service_chapter = "/service-chapter";
const route_update_service_chapter = "/update-service-chapter";
const route_service_category = "/service-category";
const route_update_service_category = "/update-service-category";
const route_service_type = "/service-type";
const route_service_chapters = "/service-chapters";
const route_delete_service_chapter = "/delete-service-chapter";
const route_delete_service_category = "/delete-service-category";
const route_delete_service_type = "/delete-service-type";
const route_service_table_types = "/service-table-types";
const route_delete_service_table_type = "/delete-service-table-type";
const route_update_service_table_type = "/update-service-table-type";
const route_operation_categories = "/operation-categories";
const route_delete_operation_category = "/delete-operation-category";
const route_update_operation_category = "/update-operation-category";
const route_operation_category = "/operation-category";
const route_service_unit_types = "/service-unit-types";
const route_delete_service_unit_type = "/delete-service-unit-type";
const route_update_service_unit_type = "/update-service-unit-type";
const route_service_unit_type = "/service-unit-type";
const route_delete_drug_manufacturer = "/delete-drug-manufacturer";
const route_update_drug_manufacturer = "/update-drug-manufacturer";
const route_drug_manufacturer = "/drug-manufacturer";
const route_service_price_update_requests = "/service-price-update-requests";
const route_provider = "/provider";
const act_tracked_service_price_update_requests =
  "/tracked-service-price-update-requests";
const act_cancel_price_update_request = "/cancel-price-update-request";
const route_service_workspaces = "/service-workspaces";
const route_service_workspace = "/service-workspace";
const route_workspace_summary = "/workspace-summary";
const route_download_service_workspace_report =
  "/download-service-workspace-report";
const act_import_service_workspace = "/import-service-workspace";
const act_add_service_drafts = "/add-service-drafts";
const act_check_duplicate = "/check-duplicate";
const act_build_matching = "/build-matching";
const act_resolve_duplicate = "/resolve-duplicate";
const act_replace_draft = "/replace-draft";
const act_rollback_draft = "/rollback-draft";
const act_commit = "/commit";

const route_service_pricing_workspaces = "/service-pricing-workspaces";
const route_service_pricing_workspace = "/service-pricing-workspace";
const act_download_service_pricing_workspace_history_report =
  "/download-service-pricing-workspace-history-report";
const route_download_service_pricing_workspace_report =
  "/download-service-pricing-workspace-report";
const act_import_service_pricing_workspace =
  "/import-service-pricing-workspace";
const act_add_service_pricing_drafts = "/add-service-pricing-drafts";

const route_add_service_discount_scheme = "/add-service-discount-scheme";
const route_update_service_discount_scheme = "/update-service-discount-scheme";
const route_delete_service_discount_scheme = "/delete-service-discount-scheme";

const route_service_discount_settings = "/service-discount-settings";
const route_delete_service_discount_setting =
  "/delete-service-discount-setting";
const route_add_service_discount_setting = "/add-service-discount-setting";
const route_update_service_discount_setting =
  "/update-service-discount-setting";
const route_cancel_service_discount_setting =
  "/cancel-service-discount-setting";
const route_reinstate_service_discount_setting =
  "/reinstate-service-discount-setting";

const route_service_discounts = "/service-discounts";
const route_add_service_discount = "/add-service-discount";
const route_update_service_discount = "/update-service-discount";
const route_cancel_service_discount = "/cancel-service-discount";
const route_reinstate_service_discount = "/reinstate-service-discount";
const route_delete_service_discount = "/delete-service-discount";

const route_service_group = "/service-group";
const route_update_service_group = "/update-service-group";
const route_delete_service_group = "/delete-service-group";
const route_checkin_service_group = "/checkin-service-group";
const route_checkout_service_group = "/checkout-service-group";

const route_service_group_services = "/service-group-services";
const route_service_group_service = "/service-group-service";
const route_cancel_service_group_service = "/cancel-service-group-service";
const route_delete_service_group_service = "/delete-service-group-service";
const route_export_service_group_services = "/export-service-group-services";
const route_import_service_group_services = "/import-service-group-services";

const route_atc_ingredients_level_1 = "/atc-ingredients-level-1";
const route_atc_ingredients_level_2 = "/atc-ingredients-level-2";
const route_atc_ingredients_level_3 = "/atc-ingredients-level-3";
const route_atc_ingredients_level_4 = "/atc-ingredients-level-4";

const route_atc_ingredient_level_1 = "/atc-ingredient-level-1";
const route_atc_ingredient_level_2 = "/atc-ingredient-level-2";
const route_atc_ingredient_level_3 = "/atc-ingredient-level-3";
const route_atc_ingredient_level_4 = "/atc-ingredient-level-4";
const route_atc_ingredient = "/atc-ingredient";

const route_update_operation_category_scheme =
  "/update-operation-category-scheme";
const route_delete_operation_category_scheme =
  "/delete-operation-category-scheme";

const route_service_operation_categories = "/service-operation-categories";
const route_add_service_operation_category = "/add-service-operation-category";
const route_update_service_operation_category =
  "/update-service-operation-category";
const route_delete_service_operation_category =
  "/delete-service-operation-category";
const route_cancel_service_operation_category =
  "/cancel-service-operation-category";
const route_reinstate_service_operation_category =
  "/reinstate-service-operation-category";

const route_service_operation_category_workspace =
  "/service-operation-category-workspace";
const route_service_operation_category_workspaces =
  "/service-operation-category-workspaces";
const act_download_report = "/download-report";
const act_import_drafts = "/import-drafts";
const act_add_drafts = "/add-drafts";

const route_service_discount_workspaces = "/service-discount-workspaces";
const route_service_discount_workspace = "/service-discount-workspace";
const act_download_workspace_history_report = "/download-history-report";
const route_chemical_compound = "/chemical-compound";
const route_update_chemical_compound = "/update-chemical-compound";
const route_delete_chemical_compound = "/delete-chemical-compound";

const route_service_indicators = "/service-indicators";
const route_service_indicator = "/service-indicator";
const route_update_service_indicator = "/update-service-indicator";
const route_delete_service_indicator = "/delete-service-indicator";

const route_download_services_report = "/download-services-report";
const route_download_claim_form_service_summary_report =
  "/download-claim-form-service-summary-report";
const route_download_services_with_active_pricing_report =
  "/download-services-with-active-pricing-report";
const route_allow_price_update = "/allow-price-update";
const route_service_price_update_request = "/service-price-update-request";
const act_reject_service_price_update_request =
  "/reject-service-price-update-request";
const act_rollback_service_price_update_request =
  "/rollback-service-price-update-request";
const act_accept_service_price_update_request =
  "/accept-service-price-update-request";
const act_service_price_update_request_attachments =
  "/service-price-update-request-attachments";
const act_update_service_code = "/update-service-code";
const act_export_service_operation_categories =
  "/export-service-operation-categories";
const act_export_service_discounts = "/export-service-discounts";
const act_auxiliary_services = "/auxiliary-services";
const route_auxiliary_service = "/auxiliary-service";
const act_update_auxiliary_service = "/update-auxiliary-service";
const act_delete_auxiliary_service = "/delete-auxiliary-service";
const route_is_auxiliary_service = "/is-auxiliary-service";
const route_requires_provider_update = "/requires-provider-update";
const act_force_checkin = "/force-checkin";
const route_potency_units = "/potency-units";
const route_pack_size_units = "/pack-size-units";
const route_medicine_packaging_types = "/medicine-packaging-types";
const route_dose_units = "/dose-units";
const route_download_service_price_update_requests_report =
  "/download-service-price-update-requests-report";

const getServices = (criteria) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getServiceDiscountSchemes = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_service_discount_scheme,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getOperationCategorySchemes = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_operation_category_scheme,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getServiceTypes = (criteria) => {
  return apiClient.post(
    endpoint + route_service_types,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getUnitConversion = (fromUnitID, toUnitID) => {
  return apiClient.post(
    endpoint + route_unit_conversion,
    JSON.stringify({
      FromUnitID: fromUnitID,
      ToUnitID: toUnitID,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getDrugManufacturers = (criteria) => {
  return apiClient.post(
    endpoint + route_drug_manufacturers,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};
const getServiceGroups = (criteria) => {
  return apiClient.post(
    endpoint + route_service_groups,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getMedicineDosageFormList = () => {
  return apiClient.get(endpoint + route_medicine_dosage_forms);
};

const addService = (criteria) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getService = (serviceID) => {
  return apiClient.get(endpoint + "/" + serviceID);
};

const getServiceTableTypeCodeList = (serviceID) => {
  return apiClient.get(
    endpoint + "/" + serviceID + route_service_table_type_codes
  );
};

const getServiceProtocolList = (serviceID, serviceTableTypeID) => {
  return apiClient.get(
    endpoint +
      "/" +
      serviceID +
      route_service_table_type +
      "/" +
      serviceTableTypeID +
      route_service_protocols
  );
};

const checkinService = (serviceID) => {
  return apiClient.put(endpoint + "/" + serviceID + route_checkin_service);
};

const checkoutService = (serviceID) => {
  return apiClient.put(endpoint + "/" + serviceID + route_checkout_service);
};

const deleteService = (serviceID) => {
  return apiClient.delete(endpoint + "/" + serviceID);
};

const cancelService = (serviceID, cancellationDate) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_cancel_service,
    JSON.stringify({
      CancellationDate: cancellationDate,
    })
  );
};

const reinstateService = (serviceID) => {
  return apiClient.put(endpoint + "/" + serviceID + route_reinstate_service);
};

const updateServiceBasicInformation = (serviceID, criteria) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_update_service_basic_information,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateServiceType = (serviceID, serviceTypeID) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_update_service_type + "/" + serviceTypeID
  );
};

const updateServiceBarcode = (serviceID, text) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_update_service_barcode,
    JSON.stringify({
      Text: text,
    })
  );
};

const setServiceIsOperation = (serviceID, isOperation) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_operation + "/" + isOperation
  );
};

const setServiceUnavailability = (serviceID, isUnavailable) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_unavailable + "/" + isUnavailable
  );
};

const setServiceIsConsultation = (serviceID, isConsultation) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_consultation + "/" + isConsultation
  );
};

const setServicePricedOnDispensation = (serviceID, isPricedOnDispensation) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_priced_on_dispensation +
      "/" +
      isPricedOnDispensation
  );
};

const setServiceShowInOnlinePortal = (serviceID, showInOnlinePortal) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_show_in_online_portal +
      "/" +
      showInOnlinePortal
  );
};

const setServiceFrequentlyUsed = (serviceID, isFrequentlyUsed) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_frequently_used + "/" + isFrequentlyUsed
  );
};

const setServiceRequiresToothNumber = (serviceID, requiresToothNumber) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_requires_tooth_number +
      "/" +
      requiresToothNumber
  );
};

const setServiceIsDetail = (serviceID, isDetail) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_detail + "/" + isDetail
  );
};

const setServiceIsGeneralExclusions = (serviceID, isGeneralExclusions) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_general_exclusions +
      "/" +
      isGeneralExclusions
  );
};

const setServiceIsRoomAndBoard = (serviceID, isRoomAndBoard) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_room_and_board + "/" + isRoomAndBoard
  );
};

const updateServiceMedicineInformation = (serviceID, criteria) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_update_service_medicine_info,
    JSON.stringify({
      ...criteria,
    })
  );
};

const setServiceRequiresResults = (serviceID, criteria) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_requires_results,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addServiceTableTypeCode = (serviceID, criteria) => {
  return apiClient.post(
    endpoint + "/" + serviceID + route_table_type_code,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteServiceTableTypeCode = (serviceID, serviceTableTypeCodeID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      serviceID +
      route_delete_table_type_code +
      "/" +
      serviceTableTypeCodeID
  );
};

const updateServiceTableTypeCode = (
  serviceID,
  serviceTableTypeCodeID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_update_table_type_code +
      "/" +
      serviceTableTypeCodeID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addServiceProtocol = (serviceID, criteria) => {
  return apiClient.post(
    endpoint + "/" + serviceID + route_service_protocol,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteServiceProtocol = (
  serviceID,
  serviceTableTypeCodeID,
  serviceProtocolID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      serviceID +
      route_table_type_code +
      "/" +
      serviceTableTypeCodeID +
      route_delete_service_protocol +
      "/" +
      serviceProtocolID
  );
};

const updateServiceProtocol = (
  serviceID,
  serviceTableTypeCodeID,
  serviceProtocolID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_table_type_code +
      "/" +
      serviceTableTypeCodeID +
      route_update_service_protocol +
      "/" +
      serviceProtocolID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const reinstateServiceProtocol = (
  serviceID,
  serviceTableTypeCodeID,
  serviceProtocolID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_table_type_code +
      "/" +
      serviceTableTypeCodeID +
      route_reinstate_service_protocol +
      "/" +
      serviceProtocolID
  );
};

const cancelServiceProtocol = (
  serviceID,
  serviceTableTypeCodeID,
  serviceProtocolID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_table_type_code +
      "/" +
      serviceTableTypeCodeID +
      route_cancel_service_protocol +
      "/" +
      serviceProtocolID,
    JSON.stringify({
      CancellationDate: cancellationDate,
    })
  );
};

const getServicePricingList = (criteria) => {
  return apiClient.post(
    endpoint + route_service_pricings,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addServicePricing = (criteria) => {
  return apiClient.post(
    endpoint + route_service_pricing,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateServicePricing = (servicePricingID, criteria) => {
  return apiClient.put(
    endpoint + route_update_service_pricing + "/" + servicePricingID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteServicePricing = (serviceID, servicePricingID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      serviceID +
      route_delete_service_pricing +
      "/" +
      servicePricingID
  );
};

const cancelServicePricing = (
  serviceID,
  servicePricingID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_cancel_service_pricing +
      "/" +
      servicePricingID,
    JSON.stringify({
      CancellationDate: cancellationDate,
    })
  );
};

const reinstateServicePricing = (serviceID, servicePricingID) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_reinstate_service_pricing +
      "/" +
      servicePricingID
  );
};

const getServiceProtocolPricings = (criteria) => {
  return apiClient.post(
    endpoint + route_service_protocol_pricings,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addServiceProtocolPricing = (criteria) => {
  return apiClient.post(
    endpoint + route_service_protocol_pricing,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateServiceProtocolPricing = (serviceProtocolPricingID, criteria) => {
  return apiClient.put(
    endpoint +
      route_update_service_protocol_pricing +
      "/" +
      serviceProtocolPricingID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const cancelServiceProtocolPricing = (
  serviceID,
  serviceProtocolID,
  serviceProtocolPricingID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_service_protocol +
      "/" +
      serviceProtocolID +
      route_cancel_service_protocol_pricing +
      "/" +
      serviceProtocolPricingID,
    JSON.stringify({
      CancellationDate: cancellationDate,
    })
  );
};

const reinstateServiceProtocolPricing = (
  serviceID,
  serviceProtocolID,
  serviceProtocolPricingID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_service_protocol +
      "/" +
      serviceProtocolID +
      route_reinstate_service_protocol_pricing +
      "/" +
      serviceProtocolPricingID
  );
};

const deleteServiceProtocolPricing = (
  serviceID,
  serviceProtocolID,
  serviceProtocolPricingID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      serviceID +
      route_service_protocol +
      "/" +
      serviceProtocolID +
      route_delete_service_protocol_pricing +
      "/" +
      serviceProtocolPricingID
  );
};

const getMedicineIngredientList = (serviceID) => {
  return apiClient.get(endpoint + "/" + serviceID + route_medicine_ingredients);
};

const getIngredients = (criteria) => {
  return apiClient.post(
    endpoint + route_atc_ingredients,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getChemicalCompounds = (criteria) => {
  return apiClient.post(
    endpoint + route_chemical_compounds,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addChemicalCompound = (chemicalCompound) => {
  return apiClient.post(
    endpoint + route_chemical_compound,
    JSON.stringify({
      ...chemicalCompound,
    })
  );
};

const updateChemicalCompound = (chemicalCompoundID, chemicalCompound) => {
  return apiClient.put(
    endpoint + route_update_chemical_compound + "/" + chemicalCompoundID,
    JSON.stringify({
      ...chemicalCompound,
    })
  );
};

const deleteChemicalCompound = (chemicalCompoundID) => {
  return apiClient.delete(
    endpoint + route_delete_chemical_compound + "/" + chemicalCompoundID
  );
};

const getServiceCategories = (criteria) => {
  return apiClient.post(
    endpoint + route_service_categories,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addMedicineIngredient = (serviceID, criteria) => {
  return apiClient.post(
    endpoint + "/" + serviceID + route_medicine_ingredient,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateMedicineIngredient = (
  serviceID,
  medicineIngredientID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_update_medicine_ingredient +
      "/" +
      medicineIngredientID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteMedicineIngredient = (serviceID, medicineIngredientID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      serviceID +
      route_delete_medicine_ingredient +
      "/" +
      medicineIngredientID
  );
};

const getServiceAttachmentList = (serviceID) => {
  return apiClient.get(endpoint + "/" + serviceID + route_service_attachments);
};

const addServiceAttachment = (serviceID, attachmentID) => {
  return apiClient.post(
    endpoint + "/" + serviceID + route_service_attachment + "/" + attachmentID
  );
};

const updateServiceAttachment = (serviceID, attachmentID) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_update_service_attachment +
      "/" +
      attachmentID
  );
};

const deleteServiceAttachment = (serviceID, attachmentID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      serviceID +
      route_delete_service_attachment +
      "/" +
      attachmentID
  );
};

const getServiceCommentList = (serviceID) => {
  return apiClient.get(endpoint + "/" + serviceID + route_service_comments);
};

const addServiceComment = (serviceID, criteria) => {
  return apiClient.post(
    endpoint + "/" + serviceID + route_service_comment,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateServiceComment = (serviceID, serviceCommentID, criteria) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_update_service_comment +
      "/" +
      serviceCommentID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteServiceComment = (serviceID, serviceCommentID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      serviceID +
      route_delete_service_comment +
      "/" +
      serviceCommentID
  );
};

const updateServiceAutomaticRejectionConfiguration = (serviceID, criteria) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_update_service_automatic_rejection,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateServiceManagementConfiguration = (serviceID, criteria) => {
  return apiClient.put(
    endpoint + "/" + serviceID + route_update_service_management_configuration,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addServiceChapter = (criteria) => {
  return apiClient.post(
    endpoint + route_service_chapter,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateServiceChapter = (serviceChapterID, criteria) => {
  return apiClient.put(
    endpoint + route_update_service_chapter + "/" + serviceChapterID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addServiceCategory = (criteria) => {
  return apiClient.post(
    endpoint + route_service_category,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateServiceCategory = (serviceCategoryID, criteria) => {
  return apiClient.put(
    endpoint + route_update_service_category + "/" + serviceCategoryID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addServiceType = (criteria) => {
  return apiClient.post(
    endpoint + route_service_type,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateServiceTypeObj = (serviceTypeID, criteria) => {
  return apiClient.put(
    endpoint + route_update_service_type + "/" + serviceTypeID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getServiceChapters = (criteria) => {
  return apiClient.post(
    endpoint + route_service_chapters,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const deleteServiceChapter = (serviceChapterID) => {
  return apiClient.delete(
    endpoint + route_delete_service_chapter + "/" + serviceChapterID
  );
};

const deleteServiceCategory = (serviceCategoryID) => {
  return apiClient.delete(
    endpoint + route_delete_service_category + "/" + serviceCategoryID
  );
};

const deleteServiceType = (serviceTypeID) => {
  return apiClient.delete(
    endpoint + route_delete_service_type + "/" + serviceTypeID
  );
};

const getServiceTableTypes = () => {
  return apiClient.get(endpoint + route_service_table_types);
};

const deleteServiceTableType = (serviceTableTypeID) => {
  return apiClient.delete(
    endpoint + route_delete_service_table_type + "/" + serviceTableTypeID
  );
};

const updateServiceTableType = (serviceTableTypeID, criteria) => {
  return apiClient.put(
    endpoint + route_update_service_table_type + "/" + serviceTableTypeID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addServiceTableType = (criteria) => {
  return apiClient.post(
    endpoint + route_service_table_type,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getOperationCategories = (criteria) => {
  return apiClient.post(
    endpoint + route_operation_categories,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const deleteOperationCategory = (operationCategoryID) => {
  return apiClient.delete(
    endpoint + route_delete_operation_category + "/" + operationCategoryID
  );
};

const updateOperationCategory = (operationCategoryID, criteria) => {
  return apiClient.put(
    endpoint + route_update_operation_category + "/" + operationCategoryID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addOperationCategory = (criteria) => {
  return apiClient.post(
    endpoint + route_operation_category,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getServiceUnitTypes = () => {
  return apiClient.get(endpoint + route_service_unit_types);
};

const deleteServiceUnitType = (serviceUnitTypeID) => {
  return apiClient.delete(
    endpoint + route_delete_service_unit_type + "/" + serviceUnitTypeID
  );
};

const updateServiceUnitType = (serviceUnitTypeID, criteria) => {
  return apiClient.put(
    endpoint + route_update_service_unit_type + "/" + serviceUnitTypeID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addServiceUnitType = (criteria) => {
  return apiClient.post(
    endpoint + route_service_unit_type,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteDrugManufacturer = (drugManufacturerID) => {
  return apiClient.delete(
    endpoint + route_delete_drug_manufacturer + "/" + drugManufacturerID
  );
};

const updateDrugManufacturer = (serviceUnitTypeID, criteria) => {
  return apiClient.put(
    endpoint + route_update_drug_manufacturer + "/" + serviceUnitTypeID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addDrugManufacturer = (criteria) => {
  return apiClient.post(
    endpoint + route_drug_manufacturer,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getServicePriceUpdateRequests = (criteria, paging) => {
  return apiClient.post(
    endpoint + route_service_price_update_requests,
    JSON.stringify({
      ...criteria,
      ...paging,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderTrackedServicePriceUpdateRequests = (providerID, paging) => {
  return apiClient.post(
    endpoint +
      route_provider +
      "/" +
      providerID +
      act_tracked_service_price_update_requests,
    JSON.stringify({
      ...paging,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const cancelServicePriceUpdateRequest = (
  servicePriceUpdateRequestID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      servicePriceUpdateRequestID +
      act_cancel_price_update_request,
    JSON.stringify({ ...criteria })
  );
};

const getServiceWorkspaces = (criteria) => {
  return apiClient.post(
    endpoint + route_service_workspaces,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getServiceWorkspaceSummary = (servicWorkspaceID) => {
  return apiClient.get(
    endpoint +
      route_service_workspace +
      "/" +
      servicWorkspaceID +
      route_workspace_summary
  );
};

const createServiceWorkspace = (serviceWorkspace) => {
  return apiClient.post(
    endpoint + route_service_workspace,
    JSON.stringify({
      ...serviceWorkspace,
    })
  );
};

const deleteServiceWorkspace = (serviceWorkspaceID) => {
  return apiClient.delete(
    endpoint + route_service_workspace + "/" + serviceWorkspaceID
  );
};

const downloadServiceWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint +
      route_service_workspace +
      route_download_service_workspace_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importServiceWorkspace = (workspaceID, formData, onUploadProgress) => {
  return apiClient.post(endpoint + act_import_service_workspace, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      WorkspaceID: workspaceID,
    },
    onUploadProgress: (progress) =>
      onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
  });
};

const addServiceDraftList = (workspaceID, serviceDrats) => {
  return apiClient.post(
    endpoint + "/" + workspaceID + act_add_service_drafts,
    JSON.stringify({ ServiceDrats: serviceDrats })
  );
};

const checkDuplicateServiceDrafts = (workspaceID, statusCode) => {
  return apiClient.get(
    endpoint +
      route_service_workspace +
      "/" +
      workspaceID +
      act_check_duplicate +
      "/" +
      statusCode
  );
};

const resolveDuplicateService = (workspaceID, criteria) => {
  return apiClient.put(
    endpoint +
      route_service_workspace +
      "/" +
      workspaceID +
      act_resolve_duplicate,
    JSON.stringify({ ...criteria })
  );
};

const commitServiceWorkspace = (workspaceID) => {
  return apiClient.put(
    endpoint + route_service_workspace + "/" + workspaceID + act_commit
  );
};

const getServicePricingWorkspaces = (criteria) => {
  return apiClient.post(
    endpoint + route_service_pricing_workspaces,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadServicePricingWorkspaceHistoryReport = (criteria) => {
  return apiClient.post(
    endpoint +
      route_service_pricing_workspace +
      act_download_service_pricing_workspace_history_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getServicePricingWorkspaceSummary = (servicPricingWorkspaceID) => {
  return apiClient.get(
    endpoint +
      route_service_pricing_workspace +
      "/" +
      servicPricingWorkspaceID +
      route_workspace_summary
  );
};

const createServicePricingWorkspace = (servicPricingWorkspaceID) => {
  return apiClient.post(
    endpoint + route_service_pricing_workspace,
    JSON.stringify({
      ...servicPricingWorkspaceID,
    })
  );
};

const deleteServicePricingWorkspace = (servicPricingWorkspaceID) => {
  return apiClient.delete(
    endpoint + route_service_pricing_workspace + "/" + servicPricingWorkspaceID
  );
};

const downloadServicePricingWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint +
      route_service_pricing_workspace +
      route_download_service_pricing_workspace_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importServicePricingWorkspace = (
  workspaceID,
  formData,
  onUploadProgress
) => {
  return apiClient.post(
    endpoint + act_import_service_pricing_workspace,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        WorkspaceID: workspaceID,
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const addServicePricingDraftList = (workspaceID, serviceDrats) => {
  return apiClient.post(
    endpoint + "/" + workspaceID + act_add_service_pricing_drafts,
    JSON.stringify({ ServicePricingDrats: serviceDrats })
  );
};

const checkDuplicateServicePricingDrafts = (workspaceID, statusCode) => {
  return apiClient.get(
    endpoint +
      route_service_pricing_workspace +
      "/" +
      workspaceID +
      act_check_duplicate +
      "/" +
      statusCode
  );
};

const resolveDuplicateServicePricing = (workspaceID, criteria) => {
  return apiClient.put(
    endpoint +
      route_service_pricing_workspace +
      "/" +
      workspaceID +
      act_resolve_duplicate,
    JSON.stringify({ ...criteria })
  );
};

const buildServicePricingDraftsMatching = (workspaceID, statusCode) => {
  return apiClient.get(
    endpoint +
      route_service_pricing_workspace +
      "/" +
      workspaceID +
      act_build_matching +
      "/" +
      statusCode
  );
};

const replaceServicePricingDraft = (workspaceID, pricingID, draftID) => {
  return apiClient.put(
    endpoint +
      route_service_pricing_workspace +
      "/" +
      workspaceID +
      route_service_pricing +
      "/" +
      pricingID +
      act_replace_draft +
      "/" +
      draftID
  );
};

const rollbackServicePricingDraft = (workspaceID, draftID) => {
  return apiClient.put(
    endpoint +
      route_service_pricing_workspace +
      "/" +
      workspaceID +
      act_rollback_draft +
      "/" +
      draftID
  );
};

const commitServicePricingWorkspace = (workspaceID) => {
  return apiClient.put(
    endpoint + route_service_pricing_workspace + "/" + workspaceID + act_commit
  );
};

const addServiceDiscountScheme = (serviceDiscountScheme) => {
  return apiClient.post(
    endpoint + route_add_service_discount_scheme,
    JSON.stringify({ ...serviceDiscountScheme })
  );
};

const updateServiceDiscountScheme = (
  serviceDiscountSchemeID,
  serviceDiscountScheme
) => {
  return apiClient.put(
    endpoint +
      route_update_service_discount_scheme +
      "/" +
      serviceDiscountSchemeID,
    JSON.stringify({ ...serviceDiscountScheme })
  );
};

const deleteServiceDiscountScheme = (serviceDiscountSchemeID) => {
  return apiClient.delete(
    endpoint +
      route_delete_service_discount_scheme +
      "/" +
      serviceDiscountSchemeID
  );
};

const getServiceDiscountSettings = (serviceDiscountSchemeID) => {
  return apiClient.get(
    endpoint + "/" + serviceDiscountSchemeID + route_service_discount_settings
  );
};

const addServiceDiscountSetting = (serviceDiscountSetting) => {
  return apiClient.post(
    endpoint + route_add_service_discount_setting,
    JSON.stringify({ ...serviceDiscountSetting })
  );
};

const updateServiceDiscountSetting = (
  serviceDiscountSettingID,
  serviceDiscountSetting
) => {
  return apiClient.put(
    endpoint +
      route_update_service_discount_setting +
      "/" +
      serviceDiscountSettingID,
    JSON.stringify({ ...serviceDiscountSetting })
  );
};

const cancelServiceDiscountSetting = (
  serviceDiscountSchemeID,
  serviceDiscountSettingID,
  toDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceDiscountSchemeID +
      route_cancel_service_discount_setting +
      "/" +
      serviceDiscountSettingID,
    JSON.stringify({ Date: toDate })
  );
};

const reinstateServiceDiscountSetting = (
  serviceDiscountSchemeID,
  serviceDiscountSettingID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceDiscountSchemeID +
      route_reinstate_service_discount_setting +
      "/" +
      serviceDiscountSettingID
  );
};

const deleteServiceDiscountSetting = (
  serviceDiscountSchemeID,
  serviceDiscountSettingID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      serviceDiscountSchemeID +
      route_delete_service_discount_setting +
      "/" +
      serviceDiscountSettingID
  );
};

const getServiceDiscounts = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_service_discounts,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addServiceDiscount = (serviceDiscountSchemeID, serviceDiscount) => {
  return apiClient.post(
    endpoint + "/" + serviceDiscountSchemeID + route_add_service_discount,
    JSON.stringify({ ...serviceDiscount })
  );
};

const updateServiceDiscount = (
  serviceDiscountSchemeID,
  serviceDiscountID,
  serviceDiscount
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceDiscountSchemeID +
      route_update_service_discount +
      "/" +
      serviceDiscountID,
    JSON.stringify({ ...serviceDiscount })
  );
};

const cancelServiceDiscount = (
  serviceDiscountSchemeID,
  serviceDiscountID,
  toDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceDiscountSchemeID +
      route_cancel_service_discount +
      "/" +
      serviceDiscountID,
    JSON.stringify({ Date: toDate })
  );
};

const reinstateServiceDiscount = (
  serviceDiscountSchemeID,
  serviceDiscountID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceDiscountSchemeID +
      route_reinstate_service_discount +
      "/" +
      serviceDiscountID
  );
};

const deleteServiceDiscount = (serviceDiscountSchemeID, serviceDiscountID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      serviceDiscountSchemeID +
      route_delete_service_discount +
      "/" +
      serviceDiscountID
  );
};

const addServiceGroup = (serviceGroup) => {
  return apiClient.post(
    endpoint + route_service_group,
    JSON.stringify({ ...serviceGroup })
  );
};

const updateServiceGroup = (serviceGroupID, serviceGroup) => {
  return apiClient.put(
    endpoint + route_update_service_group + "/" + serviceGroupID,
    JSON.stringify({ ...serviceGroup })
  );
};

const deleteServiceGroup = (serviceGroupID) => {
  return apiClient.delete(
    endpoint + route_delete_service_group + "/" + serviceGroupID
  );
};

const checkoutServiceGroup = (serviceGroupID) => {
  return apiClient.put(
    endpoint + route_checkout_service_group + "/" + serviceGroupID
  );
};

const checkinServiceGroup = (serviceGroupID) => {
  return apiClient.put(
    endpoint + route_checkin_service_group + "/" + serviceGroupID
  );
};

const getServiceGroupServices = (serviceGroupID) => {
  return apiClient.get(
    endpoint + route_service_group_services + "/" + serviceGroupID
  );
};

const addServiceGroupService = (serviceGroupID, serviceID) => {
  return apiClient.post(
    endpoint +
      route_service_group +
      "/" +
      serviceGroupID +
      route_service_group_service +
      "/" +
      serviceID
  );
};

const cancelServiceGroupService = (serviceGroupID, serviceID) => {
  return apiClient.put(
    endpoint +
      route_service_group +
      "/" +
      serviceGroupID +
      route_cancel_service_group_service +
      "/" +
      serviceID
  );
};

const deleteServiceGroupService = (serviceGroupID, serviceID) => {
  return apiClient.delete(
    endpoint +
      route_service_group +
      "/" +
      serviceGroupID +
      route_delete_service_group_service +
      "/" +
      serviceID
  );
};

const exportserviceGroupServices = (serviceGroupID, criteria) => {
  return apiClient.post(
    endpoint +
      route_service_group +
      "/" +
      serviceGroupID +
      route_export_service_group_services,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importServiceGroupServices = (
  serviceGroupID,
  formData,
  onUploadProgress
) => {
  return apiClient.post(
    endpoint +
      route_service_group +
      "/" +
      serviceGroupID +
      route_import_service_group_services,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const exportServiceGroups = (criteria) => {
  return apiClient.post(
    endpoint + route_export_service_group_services,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addOperationCategoryScheme = (operationCategoryScheme) => {
  return apiClient.post(
    endpoint + route_operation_category_scheme,
    JSON.stringify({ ...operationCategoryScheme })
  );
};

const updateOperationCategoryScheme = (
  operationCategorySchemeID,
  operationCategoryScheme
) => {
  return apiClient.put(
    endpoint +
      route_update_operation_category_scheme +
      "/" +
      operationCategorySchemeID,
    JSON.stringify({ ...operationCategoryScheme })
  );
};

const deleteOperationCategoryScheme = (serviceDiscountSchemeID) => {
  return apiClient.delete(
    endpoint +
      route_delete_operation_category_scheme +
      "/" +
      serviceDiscountSchemeID
  );
};

const getServiceOperationCategories = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_service_operation_categories,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addServiceOperationCategory = (
  operationCategorySchemeID,
  serviceOperationCategory
) => {
  return apiClient.post(
    endpoint +
      "/" +
      operationCategorySchemeID +
      route_add_service_operation_category,
    JSON.stringify({ ...serviceOperationCategory })
  );
};

const updateServiceOperationCategory = (
  operationCategorySchemeID,
  serviceOperationCategoryID,
  serviceOperationCategory
) => {
  return apiClient.put(
    endpoint +
      "/" +
      operationCategorySchemeID +
      route_update_service_operation_category +
      "/" +
      serviceOperationCategoryID,
    JSON.stringify({ ...serviceOperationCategory })
  );
};

const deleteServiceOperationCategory = (
  operationCategorySchemeID,
  serviceOperationCategoryID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      operationCategorySchemeID +
      route_delete_service_operation_category +
      "/" +
      serviceOperationCategoryID
  );
};

const cancelServiceOperationCategory = (
  operationCategorySchemeID,
  serviceOperationCategoryID,
  toDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      operationCategorySchemeID +
      route_cancel_service_operation_category +
      "/" +
      serviceOperationCategoryID,
    JSON.stringify({ Date: toDate })
  );
};

const reinstateServiceOperationCategory = (
  operationCategorySchemeID,
  serviceOperationCategoryID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      operationCategorySchemeID +
      route_reinstate_service_operation_category +
      "/" +
      serviceOperationCategoryID
  );
};

const createServiceOperationCategoryWorkspace = (
  serviceOperationCategorySchemeID,
  serviceOperationCategorySchemeWorkspace
) => {
  return apiClient.post(
    endpoint +
      "/" +
      serviceOperationCategorySchemeID +
      route_service_operation_category_workspace,
    JSON.stringify({
      ...serviceOperationCategorySchemeWorkspace,
    })
  );
};

const getServiceOperationCategoryWorkspaces = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_service_operation_category_workspaces,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getServiceOperationCategoryWorkspaceSummary = (
  operationCategoryWorkspaceID
) => {
  return apiClient.get(
    endpoint +
      route_service_operation_category_workspace +
      "/" +
      operationCategoryWorkspaceID +
      route_workspace_summary
  );
};

const deleteServiceOperationCategoryWorkspace = (
  operationCategoryWorkspaceID
) => {
  return apiClient.delete(
    endpoint +
      route_service_operation_category_workspace +
      "/" +
      operationCategoryWorkspaceID
  );
};

const downloadServiceOperationCategoryWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint + route_service_operation_category_workspace + act_download_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importServiceOperationCategoryWorkspace = (
  workspaceID,
  formData,
  onUploadProgress
) => {
  return apiClient.post(
    endpoint + route_service_operation_category_workspace + act_import_drafts,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        WorkspaceID: workspaceID,
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const addServiceOperationCategoryDraftList = (workspaceID, draftList) => {
  return apiClient.post(
    endpoint +
      "/" +
      route_service_operation_category_workspace +
      "/" +
      workspaceID +
      act_add_drafts,
    JSON.stringify({
      ServiceOperationCategoryDrafts: draftList,
    })
  );
};

const checkDuplicateServiceOperationCategoryDrafts = (workspaceID) => {
  return apiClient.get(
    endpoint +
      route_service_operation_category_workspace +
      "/" +
      workspaceID +
      act_check_duplicate
  );
};

const setDuplicateServiceOperationCategoryDetails = (workspaceID, criteria) => {
  return apiClient.put(
    endpoint +
      route_service_operation_category_workspace +
      "/" +
      workspaceID +
      act_resolve_duplicate,
    JSON.stringify({ ...criteria })
  );
};

const commitServiceOperationCategoryWorkspace = (workspaceID) => {
  return apiClient.put(
    endpoint +
      route_service_operation_category_workspace +
      "/" +
      workspaceID +
      act_commit
  );
};

const getServiceDiscountWorkspaces = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_service_discount_workspaces,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const downloadServiceDiscountWorkspaceHistoryReport = (criteria) => {
  return apiClient.post(
    endpoint +
      route_service_discount_workspace +
      act_download_workspace_history_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const createServiceDiscountWorkspace = (
  serviceDiscountSchemeID,
  serviceDiscountSchemeWorkspace
) => {
  return apiClient.post(
    endpoint + "/" + serviceDiscountSchemeID + route_service_discount_workspace,
    JSON.stringify({
      ...serviceDiscountSchemeWorkspace,
    })
  );
};

const getServiceDiscountWorkspaceSummary = (serviceDiscountWorkspaceID) => {
  return apiClient.get(
    endpoint +
      route_service_discount_workspace +
      "/" +
      serviceDiscountWorkspaceID +
      route_workspace_summary
  );
};

const deleteServiceDiscountWorkspace = (serviceDiscountWorkspaceID) => {
  return apiClient.delete(
    endpoint +
      route_service_discount_workspace +
      "/" +
      serviceDiscountWorkspaceID
  );
};

const downloadServiceDiscountWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint + route_service_discount_workspace + act_download_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importServiceDiscountWorkspace = (
  workspaceID,
  formData,
  onUploadProgress
) => {
  return apiClient.post(
    endpoint + route_service_discount_workspace + act_import_drafts,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        WorkspaceID: workspaceID,
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const addServiceDiscountDraftList = (workspaceID, draftList) => {
  return apiClient.post(
    endpoint +
      route_service_discount_workspace +
      "/" +
      workspaceID +
      act_add_drafts,
    JSON.stringify({
      ServiceDiscountDrafts: draftList,
    })
  );
};

const checkDuplicateServiceDiscountDrafts = (workspaceID) => {
  return apiClient.get(
    endpoint +
      route_service_discount_workspace +
      "/" +
      workspaceID +
      act_check_duplicate
  );
};

const resolveDuplicateServiceDiscountDraft = (workspaceID, criteria) => {
  return apiClient.put(
    endpoint +
      route_service_discount_workspace +
      "/" +
      workspaceID +
      act_resolve_duplicate,
    JSON.stringify({ ...criteria })
  );
};

const commitServiceDiscountWorkspace = (workspaceID) => {
  return apiClient.put(
    endpoint + route_service_discount_workspace + "/" + workspaceID + act_commit
  );
};

const getATCIngredientsLevel1 = (criteria) => {
  return apiClient.post(
    endpoint + route_atc_ingredients_level_1,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getATCIngredientsLevel2 = (criteria) => {
  return apiClient.post(
    endpoint + route_atc_ingredients_level_2,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getATCIngredientsLevel3 = (criteria) => {
  return apiClient.post(
    endpoint + route_atc_ingredients_level_3,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getATCIngredientsLevel4 = (criteria) => {
  return apiClient.post(
    endpoint + route_atc_ingredients_level_4,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const deleteATCIngredientLevel1 = (atcIngredientLevel1ID) => {
  return apiClient.delete(
    endpoint + route_atc_ingredient_level_1 + "/" + atcIngredientLevel1ID
  );
};

const deleteATCIngredientLevel2 = (atcIngredientLevel2ID) => {
  return apiClient.delete(
    endpoint + route_atc_ingredient_level_2 + "/" + atcIngredientLevel2ID
  );
};

const deleteATCIngredientLevel3 = (atcIngredientLevel3ID) => {
  return apiClient.delete(
    endpoint + route_atc_ingredient_level_3 + "/" + atcIngredientLevel3ID
  );
};

const deleteATCIngredientLevel4 = (atcIngredientLevel4ID) => {
  return apiClient.delete(
    endpoint + route_atc_ingredient_level_4 + "/" + atcIngredientLevel4ID
  );
};

const deleteATCIngredient = (atcIngredientID) => {
  return apiClient.delete(
    endpoint + route_atc_ingredient + "/" + atcIngredientID
  );
};

const addATCIngredient = (criteria) => {
  return apiClient.post(
    endpoint + route_atc_ingredient,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addATCIngredientLevel4 = (criteria) => {
  return apiClient.post(
    endpoint + route_atc_ingredient_level_4,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addATCIngredientLevel3 = (criteria) => {
  return apiClient.post(
    endpoint + route_atc_ingredient_level_3,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addATCIngredientLevel2 = (criteria) => {
  return apiClient.post(
    endpoint + route_atc_ingredient_level_2,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addATCIngredientLevel1 = (criteria) => {
  return apiClient.post(
    endpoint + route_atc_ingredient_level_1,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateATCIngredientLevel1 = (atcIngredientLevel1Code, criteria) => {
  return apiClient.put(
    endpoint + route_atc_ingredient_level_1 + "/" + atcIngredientLevel1Code,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateATCIngredientLevel2 = (atcIngredientLevel2Code, criteria) => {
  return apiClient.put(
    endpoint + route_atc_ingredient_level_2 + "/" + atcIngredientLevel2Code,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateATCIngredientLevel3 = (atcIngredientLevel3Code, criteria) => {
  return apiClient.put(
    endpoint + route_atc_ingredient_level_3 + "/" + atcIngredientLevel3Code,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateATCIngredientLevel4 = (atcIngredientLevel4Code, criteria) => {
  return apiClient.put(
    endpoint + route_atc_ingredient_level_4 + "/" + atcIngredientLevel4Code,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateATCIngredient = (atcIngredientID, criteria) => {
  return apiClient.put(
    endpoint + route_atc_ingredient + "/" + atcIngredientID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getServiceIndicators = (criteria) => {
  return apiClient.post(
    endpoint + route_service_indicators,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addServiceIndicator = (serviceIndicator) => {
  return apiClient.post(
    endpoint + route_service_indicator,
    JSON.stringify({ ...serviceIndicator })
  );
};

const updateServiceIndicator = (serviceIndicatorID, serviceIndicator) => {
  return apiClient.put(
    endpoint + route_update_service_indicator + "/" + serviceIndicatorID,
    JSON.stringify({ ...serviceIndicator })
  );
};

const deleteServiceIndicator = (serviceIndicatorID) => {
  return apiClient.delete(
    endpoint + route_delete_service_indicator + "/" + serviceIndicatorID
  );
};

const getServicesReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_services_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getClaimFormServiceSummaryReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_claim_form_service_summary_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getServicesWithActivePricingReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_services_with_active_pricing_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const setServiceAllowPriceUpdate = (serviceID, allowPriceUpdate) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_allow_price_update +
      "/" +
      allowPriceUpdate
  );
};

const getServicePriceUpdateRequest = (servicePriceUpdateRequestID) => {
  return apiClient.get(
    endpoint +
      route_service_price_update_request +
      "/" +
      servicePriceUpdateRequestID
  );
};

const rejectServicePriceUpdateRequest = (
  servicePriceUpdateRequestID,
  rejectionReason
) => {
  return apiClient.put(
    endpoint +
      route_service_price_update_request +
      "/" +
      servicePriceUpdateRequestID +
      act_reject_service_price_update_request,
    JSON.stringify({ Text: rejectionReason })
  );
};

const rollbackServicePriceUpdateRequest = (servicePriceUpdateRequestID) => {
  return apiClient.put(
    endpoint +
      route_service_price_update_request +
      "/" +
      servicePriceUpdateRequestID +
      act_rollback_service_price_update_request
  );
};

const acceptServicePriceUpdateRequest = (
  servicePriceUpdateRequestID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_service_price_update_request +
      "/" +
      servicePriceUpdateRequestID +
      act_accept_service_price_update_request,
    JSON.stringify({ ...criteria })
  );
};

const getServicePriceUpdateRequestAttachments = (
  servicePriceUpdateRequestID
) => {
  return apiClient.get(
    endpoint +
      route_service_price_update_request +
      "/" +
      servicePriceUpdateRequestID +
      act_service_price_update_request_attachments
  );
};

const updateServiceCode = (serviceID, criteria) => {
  return apiClient.put(
    endpoint + "/" + serviceID + act_update_service_code,
    JSON.stringify({ ...criteria })
  );
};

const exportServiceOperationCategories = (data) => {
  return apiClient.post(
    endpoint + act_export_service_operation_categories,
    data,
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const exportServiceDiscounts = (data) => {
  return apiClient.post(endpoint + act_export_service_discounts, data, {
    "Content-Type": "text/html",
    Accept: "*/*",
    "Accept-Encoding": "gzip, deflate, br",
    responseType: "arraybuffer",
    headers: {
      "X-HTTP-Method-Override": "GET",
    },
  });
};

const getAuxiliaryServices = (serviceID, serviceTableTypeID) => {
  return apiClient.get(
    endpoint +
      "/" +
      serviceID +
      route_service_table_type +
      "/" +
      serviceTableTypeID +
      act_auxiliary_services
  );
};

const addAuxiliaryService = (serviceID, criteria) => {
  return apiClient.post(
    endpoint + "/" + serviceID + route_auxiliary_service,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateAuxiliaryService = (
  serviceID,
  serviceTableTypeCodeID,
  auxiliaryServiceID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_table_type_code +
      "/" +
      serviceTableTypeCodeID +
      route_auxiliary_service +
      "/" +
      auxiliaryServiceID +
      act_update_auxiliary_service,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteAuxiliaryService = (
  serviceID,
  serviceTableTypeCodeID,
  auxiliaryServiceID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      serviceID +
      route_table_type_code +
      "/" +
      serviceTableTypeCodeID +
      route_auxiliary_service +
      "/" +
      auxiliaryServiceID +
      act_delete_auxiliary_service
  );
};

const setServiceIsAuxiliaryService = (serviceID, isAuxiliaryService) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_is_auxiliary_service +
      "/" +
      isAuxiliaryService
  );
};

const setServiceRequiresProviderUpdate = (
  serviceID,
  requiresProviderUpdate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      serviceID +
      route_requires_provider_update +
      "/" +
      requiresProviderUpdate
  );
};

const forceCheckInService = (serviceID) => {
  return apiClient.put(endpoint + "/" + serviceID + act_force_checkin);
};

const getPackSizeUnits = () => {
  return apiClient.get(endpoint + route_pack_size_units);
};

const getPotencyUnits = () => {
  return apiClient.get(endpoint + route_potency_units);
};

const getDoseUnits = () => {
  return apiClient.get(endpoint + route_dose_units);
};

const getMedicinePackagingTypes = () => {
  return apiClient.get(endpoint + route_medicine_packaging_types);
};
const downloadServicePriceUpdateRequestsReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_service_price_update_requests_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const servicesApi = {
  getServices,
  getServiceDiscountSchemes,
  getOperationCategorySchemes,
  getServiceTypes,
  getDrugManufacturers,
  getMedicineDosageFormList,
  getUnitConversion,
  addService,
  getService,
  getServiceTableTypeCodeList,
  getServiceProtocolList,
  checkinService,
  checkoutService,
  deleteService,
  cancelService,
  reinstateService,
  updateServiceBasicInformation,
  updateServiceType,
  updateServiceBarcode,
  setServiceIsOperation,
  setServiceUnavailability,
  setServiceIsConsultation,
  setServicePricedOnDispensation,
  setServiceShowInOnlinePortal,
  setServiceFrequentlyUsed,
  setServiceRequiresToothNumber,
  setServiceIsDetail,
  setServiceIsGeneralExclusions,
  setServiceIsRoomAndBoard,
  updateServiceMedicineInformation,
  setServiceRequiresResults,
  addServiceTableTypeCode,
  deleteServiceTableTypeCode,
  updateServiceTableTypeCode,
  addServiceProtocol,
  deleteServiceProtocol,
  updateServiceProtocol,
  reinstateServiceProtocol,
  cancelServiceProtocol,
  getServicePricingList,
  addServicePricing,
  updateServicePricing,
  deleteServicePricing,
  cancelServicePricing,
  reinstateServicePricing,
  getServiceProtocolPricings,
  addServiceProtocolPricing,
  updateServiceProtocolPricing,
  cancelServiceProtocolPricing,
  reinstateServiceProtocolPricing,
  deleteServiceProtocolPricing,
  getMedicineIngredientList,
  getIngredients,
  getChemicalCompounds,
  addMedicineIngredient,
  updateMedicineIngredient,
  deleteMedicineIngredient,
  getServiceAttachmentList,
  addServiceAttachment,
  updateServiceAttachment,
  deleteServiceAttachment,
  getServiceCommentList,
  addServiceComment,
  updateServiceComment,
  deleteServiceComment,
  getServiceGroups,
  getServiceCategories,
  updateServiceAutomaticRejectionConfiguration,
  updateServiceManagementConfiguration,
  addServiceChapter,
  updateServiceChapter,
  addServiceCategory,
  updateServiceCategory,
  addServiceType,
  updateServiceTypeObj,
  getServiceChapters,
  deleteServiceChapter,
  deleteServiceCategory,
  deleteServiceType,
  getServiceTableTypes,
  deleteServiceTableType,
  updateServiceTableType,
  addServiceTableType,
  getOperationCategories,
  deleteOperationCategory,
  updateOperationCategory,
  addOperationCategory,
  getServiceUnitTypes,
  deleteServiceUnitType,
  updateServiceUnitType,
  addServiceUnitType,
  deleteDrugManufacturer,
  updateDrugManufacturer,
  addDrugManufacturer,
  getServicePriceUpdateRequests,
  getProviderTrackedServicePriceUpdateRequests,
  cancelServicePriceUpdateRequest,
  getServiceWorkspaces,
  getServiceWorkspaceSummary,
  createServiceWorkspace,
  deleteServiceWorkspace,
  downloadServiceWorkspaceReport,
  importServiceWorkspace,
  addServiceDraftList,
  checkDuplicateServiceDrafts,
  resolveDuplicateService,
  commitServiceWorkspace,
  getServicePricingWorkspaces,
  downloadServicePricingWorkspaceHistoryReport,
  getServicePricingWorkspaceSummary,
  createServicePricingWorkspace,
  deleteServicePricingWorkspace,
  downloadServicePricingWorkspaceReport,
  importServicePricingWorkspace,
  addServicePricingDraftList,
  checkDuplicateServicePricingDrafts,
  resolveDuplicateServicePricing,
  buildServicePricingDraftsMatching,
  replaceServicePricingDraft,
  rollbackServicePricingDraft,
  commitServicePricingWorkspace,
  addServiceDiscountScheme,
  updateServiceDiscountScheme,
  deleteServiceDiscountScheme,
  getServiceDiscountSettings,
  addServiceDiscountSetting,
  updateServiceDiscountSetting,
  cancelServiceDiscountSetting,
  reinstateServiceDiscountSetting,
  deleteServiceDiscountSetting,
  getServiceDiscounts,
  addServiceDiscount,
  updateServiceDiscount,
  deleteServiceDiscount,
  cancelServiceDiscount,
  reinstateServiceDiscount,
  addServiceGroup,
  updateServiceGroup,
  deleteServiceGroup,
  checkoutServiceGroup,
  checkinServiceGroup,
  getServiceGroupServices,
  addServiceGroupService,
  cancelServiceGroupService,
  deleteServiceGroupService,
  exportserviceGroupServices,
  importServiceGroupServices,
  exportServiceGroups,
  addOperationCategoryScheme,
  updateOperationCategoryScheme,
  deleteOperationCategoryScheme,
  getServiceOperationCategories,
  addServiceOperationCategory,
  updateServiceOperationCategory,
  deleteServiceOperationCategory,
  cancelServiceOperationCategory,
  reinstateServiceOperationCategory,
  createServiceOperationCategoryWorkspace,
  deleteServiceOperationCategoryWorkspace,
  getServiceOperationCategoryWorkspaces,
  getServiceOperationCategoryWorkspaceSummary,
  downloadServiceOperationCategoryWorkspaceReport,
  importServiceOperationCategoryWorkspace,
  addServiceOperationCategoryDraftList,
  checkDuplicateServiceOperationCategoryDrafts,
  setDuplicateServiceOperationCategoryDetails,
  commitServiceOperationCategoryWorkspace,
  getServiceDiscountWorkspaces,
  downloadServiceDiscountWorkspaceHistoryReport,
  createServiceDiscountWorkspace,
  getServiceDiscountWorkspaceSummary,
  deleteServiceDiscountWorkspace,
  downloadServiceDiscountWorkspaceReport,
  importServiceDiscountWorkspace,
  addServiceDiscountDraftList,
  checkDuplicateServiceDiscountDrafts,
  resolveDuplicateServiceDiscountDraft,
  commitServiceDiscountWorkspace,
  addChemicalCompound,
  updateChemicalCompound,
  deleteChemicalCompound,
  getATCIngredientsLevel1,
  getATCIngredientsLevel2,
  getATCIngredientsLevel3,
  getATCIngredientsLevel4,
  deleteATCIngredientLevel1,
  deleteATCIngredientLevel2,
  deleteATCIngredientLevel3,
  deleteATCIngredientLevel4,
  deleteATCIngredient,
  addATCIngredient,
  addATCIngredientLevel4,
  addATCIngredientLevel3,
  addATCIngredientLevel2,
  addATCIngredientLevel1,
  updateATCIngredientLevel1,
  updateATCIngredientLevel2,
  updateATCIngredientLevel3,
  updateATCIngredientLevel4,
  updateATCIngredient,
  getServiceIndicators,
  addServiceIndicator,
  updateServiceIndicator,
  deleteServiceIndicator,
  getServicesReport,
  getClaimFormServiceSummaryReport,
  getServicesWithActivePricingReport,
  setServiceAllowPriceUpdate,
  getServicePriceUpdateRequest,
  rejectServicePriceUpdateRequest,
  rollbackServicePriceUpdateRequest,
  acceptServicePriceUpdateRequest,
  getServicePriceUpdateRequestAttachments,
  updateServiceCode,
  exportServiceOperationCategories,
  exportServiceDiscounts,
  getAuxiliaryServices,
  addAuxiliaryService,
  updateAuxiliaryService,
  deleteAuxiliaryService,
  setServiceIsAuxiliaryService,
  setServiceRequiresProviderUpdate,
  forceCheckInService,
  getMedicinePackagingTypes,
  getPotencyUnits,
  getPackSizeUnits,
  getDoseUnits,
  downloadServicePriceUpdateRequestsReport,
};

export default servicesApi;
