import endpoints from "./endpoints";
import ApprovalStatusModel from "../interfaces/approvals/ApprovalStatusModel";
import ProviderTypeModel from "../interfaces/providers/ProviderTypeModel";
import InsuranceCompanyModel from "../interfaces/insuranceCompany/InsuranceCompanyModel";
import ClaimTypeModel from "../interfaces/claims/ClaimTypeModel";
import CityModel from "../interfaces/geography/CityModel";
import IssuanceItemStatusModel from "../interfaces/issuance/IssuanceItemStatusModel";
import IssuanceItemTypeModel from "../interfaces/issuance/IssuanceItemTypeModel";
import CountryModel from "../interfaces/geography/CountryModel";
import ReimbursementBatchStatusModel from "../interfaces/reimbursementRequests/ReimbursementBatchStatusModel";
import IssuanceItemDetailStatusModel from "../interfaces/issuance/IssuanceItemDetailStatusModel";
import IssuanceItemDetailRejectionTypeModel from "../interfaces/issuance/IssuanceItemDetailRejectionTypeModel";
import ApprovalRequestStatusModel from "../interfaces/approvalRequests/ApprovalRequestStatusModel";
import InvoiceStatusModel from "../interfaces/invoices/InvoiceStatusModel";
import InvoiceTypeModel from "../interfaces/invoices/InvoiceTypeModel";
import CorrectionTypeModel from "../interfaces/baseConcepts/CorrectionTypeModel";
import ChronicFormModel from "../interfaces/chronicForms/ChronicFormModel";
import PrescriptionStatusModel from "./../interfaces/prescriptions/PrescriptionStatusModel";
import BillTypeModel from "../interfaces/bills/BillTypeModel";
import BillStatusModel from "../interfaces/bills/BillStatusModel";
import CoveragePlanModel from "../interfaces/coveragePlans/CoveragePlanModel";
import TransmittalStatusModel from "./../interfaces/transmittals/TransmittalStatusModel";
import ProviderSpecialtyModel from "../interfaces/providers/ProviderSpecialtyModel";
import CProviderClassificationModel from "../interfaces/providers/ProviderClassificationModel";
import InHouseTypeModel from "../interfaces/inHouse/InHouseTypeModel";
import CurrencyModel from "../common/interfaces/currencies/CurrencyModel";
import BusinessObjectModel from "../common/interfaces/BusinessObjectModel";
import ClaimFormTypeModel from "../interfaces/claims/ClaimFormTypeModel";
import ICMPaymentVoucherGroupStatusModel from "./../interfaces/pvi/ICMPaymentVoucherGroupStatusModel";
import ICMPaymentVoucherStatusModel from "../interfaces/pvi/ICMPaymentVoucherStatusModel";
import ProviderCategoryModel from "../interfaces/providers/ProviderCategoryModel";
import RebateSchemeModel from "../interfaces/rebateSchemes/RebateSchemeModel";
import ServiceUnitTypeModel from "../interfaces/serviceUnits/ServiceUnitTypeModel";
import SectorTypeModel from "../interfaces/sectorTypes/SectorTypeModel";
import ClaimCategoryModel from "../interfaces/claims/ClaimCategoryModel";
import ClaimFormFinalExaminationStatusModel from "../interfaces/batches/ClaimFormFinalExaminationStatusModel";
import BatchStatusModel from "../interfaces/batches/BatchStatusModel";
import BatchTypeModel from "../interfaces/batches/BatchTypeModel";
import ClientTypeModel from "../interfaces/client/ClientTypeModel";
import PlanTypeModel from "../interfaces/plans/PlanTypeModel";
import InsuranceTypeModel from "../interfaces/insuranceTypes/InsuranceTypeModel";
import ExclusionTypeModel from "../interfaces/exclusion/ExclusionTypeModel";
import ExclusionCategoryModel from "../interfaces/exclusion/ExclusionCategoryModel";
import InsuredRangeConfigurationModel from "../interfaces/base/InsuredRangeConfigurationModel";
import InsuredAgeConfigurationModel from "../interfaces/base/InsuredAgeConfigurationModel";
import ServiceTableTypeModel from "../interfaces/services/ServiceTableTypeModel";
import InstallmentPeriodModel from "../interfaces/base/InstallmentPeriodModel";
import PolicyTypeModel from "../interfaces/policy/PolicyTypeModel";
import ProviderGroupClassificationModel from "../interfaces/providers/ProviderGroupClassificationModel";
import InsuredRelationModel from "../interfaces/baseConcepts/InsuredRelationModel";
import ClassTypeModel from "../interfaces/classes/ClassTypeModel";
import ServiceChapterModel from "../interfaces/services/ServiceChapterModel";
import ServiceSourceModel from "../interfaces/services/ServiceSourceModel";
import ServiceNatureModel from "../interfaces/medicalFiles/medicalConditions/ServiceNatureModel";
import MedicinePackagingTypeModel from "../interfaces/services/MedicinePackagingTypeModel";
import PackSizeUnitModel from "../interfaces/services/PackSizeUnitModel";
import DoseUnitModel from "../interfaces/services/DoseUnitModel";
import PotencyUnitModel from "../interfaces/services/PotencyUnitModel";
import CollarModel from "../interfaces/baseConcepts/CollarModel";
import PolicyTaxTypeModel from "../interfaces/base/PolicyTaxTypeModel";
import ClaimReasonModel from "../interfaces/base/ClaimReasonModel";
import WorkspaceStatusModel from "../interfaces/workspaces/WorkspaceStatusModel";
import ApplicationTemplateModel from "../interfaces/applications/ApplicationTemplateModel";
import ApplicationStatusModel from "../interfaces/applications/ApplicationStatusModel";
import ApplicationWorkspaceTypeModel from "../interfaces/applications/ApplicationWorkspaceTypeModel";
import ProfessionModel from "../interfaces/base/ProfessionModel";
import StakeHolderTypeModel from "../interfaces/approvalRequests/StakeHolderTypeModel";
import TPACompanyModel from "../interfaces/tpaCompanies/TPACompanyModel";
import SupportRequestStatusModel from "../interfaces/tickets/SupportRequestStatusModel";
import RequestCategoryModel from "../interfaces/tickets/RequestCategoryModel";
import PackStatusModel from "../interfaces/packs/PackStatusModel";
import ProviderPaymentVoucherGroupStatusModel from "../interfaces/pvp/ProviderPaymentVoucherGroupStatusModel";
import ApplicationWorkspaceStatusModel from "../interfaces/applications/ApplicationWorkspaceStatusModel";
import CardTypeModel from "../interfaces/cards/CardTypeModel";
import EndorsementTypeModel from "../interfaces/policy/EndorsementTypeModel";
import RecipientTypeModel from "../interfaces/tickets/RecipientTypeModel";
import DiagnosisChapterModel from "../interfaces/Diagnoses/DiagnosisChapterModel";
import PolicyClassificatonModel from "../interfaces/policy/PolicyClassificationModel";
import ApprovalAlertLevelModel from "../interfaces/base/ApprovalAlertLevelModel";
import ClientPaymentVoucherGroupStatusModel from "../interfaces/pvc/ClientPaymentVoucherGroupStatusModel";
import WorkflowObjectConfigurationModel from "../common/interfaces/businessObjects/WorkflowObjectConfigurationModel";
import BankModel from "../interfaces/banks/BankModel";
import PVPStatusModel from "../interfaces/pvp/PVPStatusModel";
import PenaltyTypeModel from "../interfaces/penaltyTypes/PenaltyTypeModel";
import ClientPaymentVoucherStatusModel from "../interfaces/pvc/ClientPaymentVoucherStatusModel";
import ClientDebitVoucherStatusModel from "../interfaces/clientDebitVouchers/ClientDebitVoucherStatusModel";
import InsuredCancellationWorkspaceStatusModel from "../interfaces/policy/InsuredCancellationWorkspaceStatusModel";
import InsuredTransferWorkspaceStatusModel from "../interfaces/policy/InsuredTransferWorkspaceStatusModel";
import FundTypeModel from "../interfaces/funds/FundTypeModel";
import FundStatusModel from "../interfaces/funds/FundStatusModel";
import CardIssuanceWorkspaceStatusModel from "../interfaces/policy/CardIssuanceWorkspaceStatusModel";
import PopulationTypeModel from "../interfaces/funds/PopulationTypeModel";
import ServiceWorkspaceStatusModel from "../interfaces/services/ServiceWorkspaceStatusModel";
import ServiceWorkspaceTypeModel from "../interfaces/services/ServiceWorkspaceTypeModel";
import ServicePricingWorkspaceStatusModel from "../interfaces/services/ServicePricingWorkspaceStatusModel";
import InvoicePaymentStatusModel from "../interfaces/invoices/InvoicePaymentStatusModel";
import MoneyTransferMethodModel from "../interfaces/moneyTransfer/MoneyTransferMethodModel";
import PaymentSourceModel from "../interfaces/base/PaymentSourceModel";
import BillPaymentStatusModel from "../interfaces/billPayments/BillPaymentStatusModel";
import IndustryModel from "./../interfaces/base/IndustryModel";
import TitleModel from "../interfaces/base/TitleModel";
import OfficialLetterTypeModel from "../interfaces/officialLetters/OfficialLetterTypeModel";
import ReimbursementRequestStatusModel from "../interfaces/reimbursementRequests/ReimbursementRequestStatusModel";
import RelationOfficerTypeModel from "../interfaces/corporateRelations/RelationOfficerTypeModel";
import OfferedServiceModel from "../interfaces/offeredServices/OfferedServiceModel";
import ProviderTransmittalStatusModel from "../interfaces/providerTransmittals/ProviderTransmittalStatusModel";
import ProviderTransmittalTypeModel from "../interfaces/providerTransmittals/ProviderTransmittalTypeModel";
import AppointmentStatusModel from "../interfaces/appointments/AppointmentStatusModel";
import InHouseProviderCompensationTypeModel from "../interfaces/inHouse/InHouseProviderCompensationTypeModel";
import CommunicationTypeModel from "../interfaces/base/CommunicationTypeModel";
import AccountTypeModel from "../interfaces/accounts/AccountTypeModel";
import AccountCategoryModel from "../interfaces/accounts/AccountCategoryModel";
import AccountingEntityTypeModel from "../interfaces/accounts/AccountingEntityTypeModel";
import AccountingTransactionTypeModel from "../interfaces/accountingTransactions/AccountingTransactionTypeModel";
import AccountingTransactionStatusModel from "../interfaces/accountingTransactions/AccountingTransactionStatusModel";
import AccountingTransactionActionTypeModel from "../interfaces/accountingTransactions/AccountingTransactionActionTypeModel";
import InsuredMobileNumberWorkspaceStatusModel from "../interfaces/insuredMobileNumberWorkspace/InsuredMobileNumberWorkspaceStatusModel";
import GovernorateModel from "../interfaces/geography/GovernorateModel";
import GeographicalScopeModel from "./../interfaces/geography/GeographicalScopeModel";
import ClaimFormStatusModel from "../interfaces/claims/ClaimFormStatusModel";
import DiagnosticTestTypeModel from "../interfaces/diagnosticTests/DiagnosticTestTypeModel";
import BillingTypeModel from "../interfaces/base/BillingTypeModel";
import TreasuryTransactionTypeModel from "../interfaces/treasury/TreasuryTransactionTypeModel";
import AccountBasicTypeModel from "../interfaces/accounts/AccountBasicTypeModel";
import ServicePriceUpdateRequestStatusModel from "../interfaces/services/ServicePriceUpdateRequestStatusModel";
import TransactionRequiresApprovalReasonModel from "../interfaces/claims/TransactionRequiresApprovalReasonModel";
import PersonWorkspaceStatusModel from "../interfaces/person/PersonWorkspaceStatusModel";
import ApprovalTypeModel from "../interfaces/approvals/ApprovalTypeModel";
import BillPaymentProcessingWorkspaceStatusModel from "../interfaces/billPaymentProcessingWorkspaces/BillPaymentProcessingWorkspaceStatusModel";
import ApprovalSegmentModel from "../interfaces/approvals/ApprovalSegmentModel";
import ChronicFormRequestStatusModel from "../interfaces/chronicFormRequests/ChronicFormRequestStatusModel";
import ChronicFormRequestTypeModel from "../interfaces/chronicFormRequests/ChronicFormRequestTypeModel";
import DataFieldModel from "../interfaces/base/DataFieldModel";
import AdvancePaymentStatusModel from "../interfaces/advancePayments/AdvancePaymentStatusModel";
import ApprovalCategoryModel from "../interfaces/approvals/ApprovalCategoryModel";
import ProviderGroupMemberWorkspaceStatusModel from "../interfaces/providers/ProviderGroupMemberWorkspaceStatusModel";
import DigitalPrescriptionStatusModel from "../interfaces/digitalPrescriptions/DigitalPrescriptionStatusModel";
import CommunicationReceiverTypeModel from "../interfaces/applications/CommunicationReceiverTypeModel";
import InvoiceGeneratorTypeModel from "../interfaces/invoices/InvoiceGeneratorTypeModel";
import CheckupStatusModel from "../interfaces/checkup/CheckupStatusModel";

export default Object.freeze({
  CApprovalStatusDefinition: {
    endpoint: endpoints.APPROVALS + "/approval-statuses",
    objectType: {} as ApprovalStatusModel,
    callType: "get" as "get" | "post",
  },
  CProviderTypeDefinition: {
    endpoint: endpoints.PROVIDERS + "/provider-types",
    objectType: {} as ProviderTypeModel,
    callType: "get" as "get" | "post",
  },
  CInsuranceCompanyDefinition: {
    endpoint: endpoints.INSURANCE_COMPANIES,
    objectType: {} as InsuranceCompanyModel,
    callType: "post" as "get" | "post",
  },
  ClaimTypeDefinition: {
    endpoint: endpoints.CLAIMS + "/claim-types",
    objectType: {} as ClaimTypeModel,
    callType: "post" as "get" | "post",
  },
  CApprovalRequestRevisionStatusDefinition: {
    endpoint:
      endpoints.APPROVAL_REQUESTS + "/approval-request-revision-statuses",
    objectType: {} as ApprovalRequestStatusModel,
    callType: "get" as "get" | "post",
  },
  CApprovalRequestStatusDefinition: {
    endpoint: endpoints.APPROVAL_REQUESTS + "/approval-request-statuses",
    objectType: {} as ApprovalRequestStatusModel,
    callType: "get" as "get" | "post",
  },
  CIssuanceItemStatusDefinition: {
    endpoint: endpoints.ISSUANCE_ITEMS + "/issuance-item-status",
    objectType: {} as IssuanceItemStatusModel,
    callType: "get" as "get" | "post",
  },
  CIssuanceItemDetailStatusDefinition: {
    endpoint: endpoints.ISSUANCE_ITEMS + "/issuance-item-detail-status",
    objectType: {} as IssuanceItemDetailStatusModel,
    callType: "get" as "get" | "post",
  },
  CIssuanceItemDetailRejectionTypeDefinition: {
    endpoint:
      endpoints.ISSUANCE_ITEMS + "/issuance-item-detail-rejection-types",
    objectType: {} as IssuanceItemDetailRejectionTypeModel,
    callType: "get" as "get" | "post",
  },
  CIssuanceItemTypeDefinition: {
    endpoint: endpoints.ISSUANCE_ITEMS + "/issuance-item-types",
    objectType: {} as IssuanceItemTypeModel,
    callType: "get" as "get" | "post",
  },
  CityDefinition: {
    endpoint: endpoints.COUNTRIES + "/cities",
    actEndpoint: "/cities",
    objectType: {} as CityModel,
    callType: "post" as "get" | "post",
  },
  CountryDefinition: {
    endpoint: endpoints.COUNTRIES,
    objectType: {} as CountryModel,
    callType: "post" as "get" | "post",
  },
  CReimbursementBatchStatusDefinition: {
    endpoint:
      endpoints.REIMBURSEMENT_REQUESTS + "/reimbursement-batch-statuses",
    objectType: {} as ReimbursementBatchStatusModel,
    callType: "get" as "get" | "post",
  },
  CInvoiceStatusDefinition: {
    endpoint: endpoints.INVOICES + "/invoice-status-list",
    objectType: {} as InvoiceStatusModel,
    callType: "get" as "get" | "post",
  },
  CInvoiceTypeDefinition: {
    endpoint: endpoints.INVOICES + "/invoice-types",
    objectType: {} as InvoiceTypeModel,
    callType: "get" as "get" | "post",
  },
  CCorrectionTypeDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/correction-types",
    objectType: {} as CorrectionTypeModel,
    callType: "get" as "get" | "post",
  },
  CChronicFormStatusDefinition: {
    endpoint: endpoints.CHRONIC_FORMS + "/chronic-form-statuses",
    objectType: {} as ChronicFormModel,
    callType: "get" as "get" | "post",
  },
  CPrescriptionStatusDefinition: {
    endpoint: endpoints.PRESCRIPTIONS + "/prescription-statuses",
    objectType: {} as PrescriptionStatusModel,
    callType: "get" as "get" | "post",
  },
  CBillTypeDefinition: {
    endpoint: endpoints.BILLS + "/bill-types",
    objectType: {} as BillTypeModel,
    callType: "get" as "get" | "post",
  },
  CBillStatusDefinition: {
    endpoint: endpoints.BILLS + "/bill-statuses",
    objectType: {} as BillStatusModel,
    callType: "get" as "get" | "post",
  },
  CPolicyCoveragePlan: {
    endpoint: endpoints.PLANS + "/plan-related-coverage-plans",
    objectType: {} as CoveragePlanModel,
    callType: "post" as "get" | "post",
  },
  CTransmittalStatusDefinition: {
    endpoint: endpoints.TRANSMITTALS + "/transmittal-statuses",
    objectType: {} as TransmittalStatusModel,
    callType: "post" as "get" | "post",
  },
  CProviderSpecialtyDefinition: {
    endpoint: endpoints.PROVIDERS + "/provider-specialties",
    objectType: {} as ProviderSpecialtyModel,
    callType: "get" as "get" | "post",
  },
  CProviderClassificationDefinition: {
    endpoint: endpoints.PROVIDERS + "/provider-classifications",
    objectType: {} as CProviderClassificationModel,
    callType: "get" as "get" | "post",
  },
  CInHouseTypeDefinition: {
    endpoint: endpoints.PROVIDERS + "/in-house-types",
    objectType: {} as InHouseTypeModel,
    callType: "get" as "get" | "post",
  },
  CCurrencyDefinition: {
    endpoint: endpoints.CURRENCIES,
    objectType: {} as CurrencyModel,
    callType: "get" as "get" | "post",
  },

  CLanguageDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/languages",
    objectType: {} as BusinessObjectModel,
    callType: "get" as "get" | "post",
  },
  CClaimFormTypeDefinition: {
    endpoint: endpoints.CLAIMS + "/claim-form-types",
    objectType: {} as ClaimFormTypeModel,
    callType: "post" as "get" | "post",
  },
  CICMPaymentVoucherGroupStatusDefinition: {
    endpoint: endpoints.PVI + "/icm-payment-voucher-group-status",
    objectType: {} as ICMPaymentVoucherGroupStatusModel,
    callType: "post" as "get" | "post",
  },
  CICMPaymentVoucherStatusDefinition: {
    endpoint: endpoints.PVI + "/icm-payment-voucher-status",
    objectType: {} as ICMPaymentVoucherStatusModel,
    callType: "post" as "get" | "post",
  },

  CTitlesDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/titles",
    objectType: {} as BusinessObjectModel,
    callType: "post" as "get" | "post",
  },
  CProviderCategoryDefinition: {
    endpoint: endpoints.PROVIDERS + "/provider-categories",
    objectType: {} as ProviderCategoryModel,
    callType: "get" as "get" | "post",
  },
  CRebateSchemeDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/rebate-schemes",
    objectType: {} as RebateSchemeModel,
    callType: "get" as "get" | "post",
  },
  CurrencyDefinition: {
    endpoint: endpoints.CURRENCIES,
    objectType: {} as CurrencyModel,
    callType: "get" as "get" | "post",
  },
  ServiceUnitTypeDefinition: {
    endpoint: endpoints.SERVICES + "/service-unit-types",
    objectType: {} as ServiceUnitTypeModel,
    callType: "get" as "get" | "post",
  },
  SectorTypeDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/sector-types",
    objectType: {} as SectorTypeModel,
    callType: "get" as "get" | "post",
  },
  CClaimCategoryDefinition: {
    endpoint: endpoints.CLAIMS + "/claim-categories",
    objectType: {} as ClaimCategoryModel,
    callType: "get" as "get" | "post",
  },
  CClaimFormFinalExaminationStatusDefinition: {
    endpoint: endpoints.BATCHES + "/claim-form-batch-examination-statuses",
    objectType: {} as ClaimFormFinalExaminationStatusModel,
    callType: "get" as "get" | "post",
  },
  CBatchStatusDefinition: {
    endpoint: endpoints.BATCHES + "/batch-statuses",
    objectType: {} as BatchStatusModel,
    callType: "post" as "get" | "post",
  },
  CBatchTypeDefinition: {
    endpoint: endpoints.BATCHES + "/batch-types",
    objectType: {} as BatchTypeModel,
    callType: "post" as "get" | "post",
  },
  CClientTypeDefinition: {
    endpoint: endpoints.CLIENTS + "/client-types",
    objectType: {} as ClientTypeModel,
    callType: "get" as "get" | "post",
  },
  CPlanTypeDefinition: {
    endpoint: endpoints.PLANS + "/plan-types",
    objectType: {} as PlanTypeModel,
    callType: "get" as "get" | "post",
  },
  CInsuranceTypeDefinition: {
    endpoint: endpoints.INSURANCE_TYPES, //+ "/insurance-types",
    objectType: {} as InsuranceTypeModel,
    callType: "get" as "get" | "post",
  },
  CExclusionTypeDefinition: {
    endpoint: endpoints.PLANS + "/exclusion-types",
    objectType: {} as ExclusionTypeModel,
    callType: "get" as "get" | "post",
  },
  CExclusionCategoryDefinition: {
    endpoint: endpoints.PLANS + "/exclusion-categories",
    objectType: {} as ExclusionCategoryModel,
    callType: "get" as "get" | "post",
  },
  CInsuredRangeConfigurationDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/insured-range-configuration",
    objectType: {} as InsuredRangeConfigurationModel,
    callType: "get" as "get" | "post",
  },
  CInsuredAgeConfigurationDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/insured-age-configuration",
    objectType: {} as InsuredAgeConfigurationModel,
    callType: "get" as "get" | "post",
  },
  CServiceTableTypeDefinition: {
    endpoint: endpoints.SERVICES + "/service-table-types",
    objectType: {} as ServiceTableTypeModel,
    callType: "get" as "get" | "post",
  },
  CInstallmentPeriodDefinition: {
    endpoint: endpoints.INSTALLMENT_PERIODS,
    objectType: {} as InstallmentPeriodModel,
    callType: "get" as "get" | "post",
  },
  CPolicyTypeDefinition: {
    endpoint: endpoints.POLICIES + "/policy-types",
    objectType: {} as PolicyTypeModel,
    callType: "get" as "get" | "post",
  },
  CProviderGroupClassificationDefinition: {
    endpoint:
      endpoints.PROVIDERS +
      endpoints.PROVIDER_GROUPS +
      "/provider-group-classifications",
    objectType: {} as ProviderGroupClassificationModel,
    callType: "get" as "get" | "post",
  },
  CInsuredRelationDefinition: {
    endpoint: endpoints.INSURED_RELATIONS,
    objectType: {} as InsuredRelationModel,
    callType: "get" as "get" | "post",
  },
  CCollarDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/collars",
    objectType: {} as CollarModel,
    callType: "get" as "get" | "post",
  },
  CPolicyTaxTypeDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/policy-tax-types",
    objectType: {} as PolicyTaxTypeModel,
    callType: "get" as "get" | "post",
  },
  CServiceChapterDefinition: {
    endpoint: endpoints.SERVICES + "/service-chapters",
    objectType: {} as ServiceChapterModel,
    callType: "post" as "get" | "post",
  },
  CServiceSourceDefinition: {
    endpoint: endpoints.SERVICES + "/service-sources",
    objectType: {} as ServiceSourceModel,
    callType: "get" as "get" | "post",
  },
  CServiceNatureDefinition: {
    endpoint: endpoints.SERVICES + "/service-natures",
    objectType: {} as ServiceNatureModel,
    callType: "get" as "get" | "post",
  },
  CMedicinePackagingTypeDefinition: {
    endpoint: endpoints.SERVICES + "/medicine-packaging-types",
    objectType: {} as MedicinePackagingTypeModel,
    callType: "get" as "get" | "post",
  },
  CPackSizeUnitDefinition: {
    endpoint: endpoints.SERVICES + "/pack-size-units",
    objectType: {} as PackSizeUnitModel,
    callType: "get" as "get" | "post",
  },
  CDoseUnitDefinition: {
    endpoint: endpoints.SERVICES + "/dose-units",
    objectType: {} as DoseUnitModel,
    callType: "get" as "get" | "post",
  },
  CPotencyUnitDefinition: {
    endpoint: endpoints.SERVICES + "/potency-units",
    objectType: {} as PotencyUnitModel,
    callType: "get" as "get" | "post",
  },
  COperationCategoryDefinition: {
    endpoint: endpoints.SERVICES + "/operation-categories",
    objectType: {} as PotencyUnitModel,
    callType: "post" as "get" | "post",
  },
  CClassTypeDefinition: {
    endpoint: endpoints.CLASS_TYPES,
    objectType: {} as ClassTypeModel,
    callType: "post" as "get" | "post",
  },
  CClaimReason: {
    endpoint: endpoints.BASE_CONCEPTS + "/claim-reasons",
    objectType: {} as ClaimReasonModel,
    callType: "get" as "get" | "post",
  },
  CTransmittalClaimWorkspaceStatusDefinition: {
    endpoint: endpoints.TRANSMITTALS + "/transmittal-claim-workspace-status",
    objectType: {} as WorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CApplicationTemplateDefinition: {
    endpoint: endpoints.APPLICATIONS + "/application-templates",
    objectType: {} as ApplicationTemplateModel,
    callType: "post" as "get" | "post",
  },
  CApplicationStatusDefinition: {
    endpoint: endpoints.APPLICATIONS + "/application-status",
    objectType: {} as ApplicationStatusModel,
    callType: "post" as "get" | "post",
  },
  CApplicationWorkspaceTypeDefinition: {
    endpoint: endpoints.APPLICATION_WORKSPACES + "/application-workspace-types",
    objectType: {} as ApplicationWorkspaceTypeModel,
    callType: "post" as "get" | "post",
  },
  CProfessionDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/professions",
    objectType: {} as ProfessionModel,
    callType: "post" as "get" | "post",
  },
  CStakeholderTypeDefinition: {
    endpoint: endpoints.TICKETS + "/stakeholder-types",
    objectType: {} as StakeHolderTypeModel,
    callType: "get" as "get" | "post",
  },
  CTPACompanyDefinition: {
    endpoint: endpoints.TPA_COMPANIES,
    objectType: {} as TPACompanyModel,
    callType: "get" as "get" | "post",
  },
  CSupportRequestStatusDefinition: {
    endpoint: endpoints.TICKETS + "/support-request-status",
    objectType: {} as SupportRequestStatusModel,
    callType: "get" as "get" | "post",
  },
  CRequestCategoryDefinition: {
    endpoint: endpoints.TICKETS + "/request-categories",
    objectType: {} as RequestCategoryModel,
    callType: "post" as "get" | "post",
  },
  CPackStatusDefinition: {
    endpoint: endpoints.PACKS + "/pack-status",
    objectType: {} as PackStatusModel,
    callType: "post" as "get" | "post",
  },
  CProviderPaymentVoucherGroupStatusDefinition: {
    endpoint: endpoints.PVP + "/provider-payment-voucher-group-status",
    objectType: {} as ProviderPaymentVoucherGroupStatusModel,
    callType: "post" as "get" | "post",
  },
  CApplicationWorkspaceStatusDefinition: {
    endpoint:
      endpoints.APPLICATION_WORKSPACES + "/application-workspace-status",
    objectType: {} as ApplicationWorkspaceStatusModel,
    callType: "post" as "get" | "post",
  },
  CCardTypeDefinition: {
    endpoint: endpoints.POLICIES + "/card-types",
    objectType: {} as CardTypeModel,
    callType: "post" as "get" | "post",
  },
  CEndorsementTypeDefinition: {
    endpoint: endpoints.POLICIES + "/endorsement-types",
    objectType: {} as EndorsementTypeModel,
    callType: "post" as "get" | "post",
  },
  CRecipientTypeDefinition: {
    endpoint: endpoints.EMAILS + "/recipient-types",
    objectType: {} as RecipientTypeModel,
    callType: "get" as "get" | "post",
  },
  CWorkflowObjectConfigurationDefinition: {
    endpoint: endpoints.WORKFLOW_OBJECTS + "/workflow-object-configurations",
    objectType: {} as WorkflowObjectConfigurationModel,
    callType: "get" as "get" | "post",
  },
  CDiagnosisChapterDefinition: {
    endpoint: endpoints.DIAGNOSES + "/diagnosis-chapters",
    objectType: {} as DiagnosisChapterModel,
    callType: "post" as "get" | "post",
  },
  CPolicyClassificationDefinition: {
    endpoint: endpoints.POLICIES + "/policy-classifications",
    objectType: {} as PolicyClassificatonModel,
    callType: "post" as "get" | "post",
  },
  CApprovalAlertLevelDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/approval-alert-levels",
    objectType: {} as ApprovalAlertLevelModel,
    callType: "post" as "get" | "post",
  },
  CClientPaymentVoucherGroupStatusDefinition: {
    endpoint: endpoints.PVC + "/client-payment-voucher-group-status",
    objectType: {} as ClientPaymentVoucherGroupStatusModel,
    callType: "post" as "get" | "post",
  },
  CPolicyAdministrationCostInstallmentTypeDefinition: {
    endpoint:
      endpoints.POLICIES + "/policy-administration-cost-installment-types",
    objectType: {} as PolicyClassificatonModel,
    callType: "post" as "get" | "post",
  },
  CBankDefinition: {
    endpoint: endpoints.BANKS,
    objectType: {} as BankModel,
    callType: "post" as "get" | "post",
  },
  CProviderPaymentVoucherStatusDefinition: {
    endpoint: endpoints.PVP + "/provider-payment-voucher-status",
    objectType: {} as PVPStatusModel,
    callType: "post" as "get" | "post",
  },
  CPenaltyTypeDefinition: {
    endpoint: endpoints.PVP + "/penalty-types",
    objectType: {} as PenaltyTypeModel,
    callType: "get" as "get" | "post",
  },
  CClientPaymentVoucherStatusDefinition: {
    endpoint: endpoints.PVC + "/client-payment-voucher-status",
    objectType: {} as ClientPaymentVoucherStatusModel,
    callType: "get" as "get" | "post",
  },
  CClientDebitVoucherStatusDefinition: {
    endpoint: endpoints.CLIENT_DEBIT_VOUCHERS + "/client-debit-voucher-status",
    objectType: {} as ClientDebitVoucherStatusModel,
    callType: "get" as "get" | "post",
  },
  CInsuredCancellationWorkspaceStatusDefinition: {
    endpoint: endpoints.POLICIES + "/insured-cancellation-workspace-status",
    objectType: {} as InsuredCancellationWorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CInsuredTransferWorkspaceStatusDefinition: {
    endpoint: endpoints.POLICIES + "/insured-transfer-workspace-status",
    objectType: {} as InsuredTransferWorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CFundStatusDefinition: {
    endpoint: endpoints.FUNDS + "/fund-status",
    objectType: {} as FundStatusModel,
    callType: "get" as "get" | "post",
  },
  CFundTypeDefinition: {
    endpoint: endpoints.FUNDS + "/fund-types",
    objectType: {} as FundTypeModel,
    callType: "get" as "get" | "post",
  },
  CProviderServiceMappingWorkspaceStatusDefinition: {
    endpoint:
      endpoints.PROVIDERS + "/provider-service-mapping-workspace-status",
    objectType: {} as BusinessObjectModel,
    callType: "get" as "get" | "post",
  },
  CCardIssuanceWorkspaceStatusDefinition: {
    endpoint: endpoints.POLICIES + "/card-issuance-workspace-status",
    objectType: {} as CardIssuanceWorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CPopulationTypeDefinition: {
    endpoint: endpoints.FUNDS + "/population-types",
    objectType: {} as PopulationTypeModel,
    callType: "get" as "get" | "post",
  },
  CServiceWorkspaceStatusDefinition: {
    endpoint: endpoints.SERVICES + "/service-workspace-statuses",
    objectType: {} as ServiceWorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CServiceWorkspaceTypeDefinition: {
    endpoint: endpoints.SERVICES + "/service-workspace-types",
    objectType: {} as ServiceWorkspaceTypeModel,
    callType: "get" as "get" | "post",
  },
  CServicePricingWorkspaceStatusDefinition: {
    endpoint: endpoints.SERVICES + "/service-pricing-workspace-statuses",
    objectType: {} as ServicePricingWorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CInvoicePaymentStatusDefinition: {
    endpoint: endpoints.INVOICES + "/invoice-payment-status-list",
    objectType: {} as InvoicePaymentStatusModel,
    callType: "get" as "get" | "post",
  },
  CMoneyTransferMethodTypeDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/money-transfer-method-types",
    objectType: {} as MoneyTransferMethodModel,
    callType: "post" as "get" | "post",
  },
  CPaymentSourceDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/payment-sources",
    objectType: {} as PaymentSourceModel,
    callType: "get" as "get" | "post",
  },
  CBillPaymentStatusDefinition: {
    endpoint: endpoints.BILL_PAYMENTS + "/bill-payment-statuses",
    objectType: {} as BillPaymentStatusModel,
    callType: "get" as "get" | "post",
  },
  CIndustryDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/industries",
    objectType: {} as IndustryModel,
    callType: "post" as "get" | "post",
  },
  CTitleDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/titles",
    objectType: {} as TitleModel,
    callType: "get" as "get" | "post",
  },
  COfficialLetterTypeDefinition: {
    endpoint: endpoints.OFFICIAL_LETTERS + "/official-letter-types",
    objectType: {} as OfficialLetterTypeModel,
    callType: "get" as "get" | "post",
  },
  CReimbursementRequestStatusDefinition: {
    endpoint:
      endpoints.REIMBURSEMENT_REQUESTS + "/reimbursement-request-statuses",
    objectType: {} as ReimbursementRequestStatusModel,
    callType: "get" as "get" | "post",
  },
  CRelationOfficerTypeDefinition: {
    endpoint: endpoints.CORPORATE_RELATION + "/relation-officer-types",
    objectType: {} as RelationOfficerTypeModel,
    callType: "post" as "get" | "post",
  },
  COfferedServiceDefinition: {
    endpoint: endpoints.OFFERED_SERVICES + "/offered-service-list",
    objectType: {} as OfferedServiceModel,
    callType: "post" as "get" | "post",
  },
  CWebRequestStatusDefinition: {
    endpoint: endpoints.PROVIDERS + "/web-request-status",
    objectType: {} as TransmittalStatusModel,
    callType: "get" as "get" | "post",
  },
  CProviderTransmittalStatusDefinition: {
    endpoint:
      endpoints.PROVIDER_TRANSMITTALS + "/provider-transmittal-statuses",
    objectType: {} as ProviderTransmittalStatusModel,
    callType: "post" as "get" | "post",
  },
  CProviderTransmittalTypeDefinition: {
    endpoint: endpoints.PROVIDER_TRANSMITTALS + "/provider-transmittal-types",
    objectType: {} as ProviderTransmittalTypeModel,
    callType: "post" as "get" | "post",
  },
  CAppointmentStatusDefinition: {
    endpoint: endpoints.APPOINTMENTS + "/appointment-status",
    objectType: {} as AppointmentStatusModel,
    callType: "post" as "get" | "post",
  },
  CServiceDiscountWorkspaceStatusDefinition: {
    endpoint: endpoints.SERVICES + "/service-discoun-workspace-statuses",
    objectType: {} as ServiceWorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CInHouseProviderCompensationTypeDefinition: {
    endpoint: endpoints.PROVIDERS + "/in-house-provider-compensation-types",
    objectType: {} as InHouseProviderCompensationTypeModel,
    callType: "get" as "get" | "post",
  },
  CCommunicationTypeDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/communication-types",
    objectType: {} as CommunicationTypeModel,
    callType: "get" as "get" | "post",
  },
  CAccountTypeDefinition: {
    endpoint: endpoints.ACCOUNTS + "/account-types",
    objectType: {} as AccountTypeModel,
    callType: "post" as "get" | "post",
  },
  CAccountCategoryDefinition: {
    endpoint: endpoints.ACCOUNTS + "/account-categories",
    objectType: {} as AccountCategoryModel,
    callType: "post" as "get" | "post",
  },
  CAccountingEntityTypeDefinition: {
    endpoint: endpoints.ACCOUNTS + "/accounting-entity-types",
    objectType: {} as AccountingEntityTypeModel,
    callType: "get" as "get" | "post",
  },
  CAccountingTransactionStatusDefinition: {
    endpoint: endpoints.TRANSACTIONS + "/accounting-transaction-status",
    objectType: {} as AccountingTransactionStatusModel,
    callType: "get" as "get" | "post",
  },
  CAccountingTransactionTypeDefinition: {
    endpoint: endpoints.TRANSACTIONS + "/accounting-transaction-types",
    objectType: {} as AccountingTransactionTypeModel,
    callType: "post" as "get" | "post",
  },
  CAccountingTransactionActionTypeDefinition: {
    endpoint: endpoints.TRANSACTIONS + "/accounting-transaction-action-types",
    objectType: {} as AccountingTransactionActionTypeModel,
    callType: "get" as "get" | "post",
  },
  CInsuredMobileNumberWorkspaceStatusDefinition: {
    endpoint: endpoints.INSURED + "/insured-mobile-number-workspace-status",
    objectType: {} as InsuredMobileNumberWorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CGovernorateDefinition: {
    endpoint: endpoints.COUNTRIES + "/governorates",
    objectType: {} as GovernorateModel,
    callType: "post" as "get" | "post",
  },
  CGeographicalScopeDefinition: {
    endpoint: endpoints.COUNTRIES + "/geographical-scopes",
    objectType: {} as GeographicalScopeModel,
    callType: "post" as "get" | "post",
  },
  CClaimFormStatusDefinition: {
    endpoint: endpoints.CLAIMS + "/claim-form-status",
    objectType: {} as ClaimFormStatusModel,
    callType: "get" as "get" | "post",
  },
  CFileReviewStatusDefinition: {
    endpoint: endpoints.FILE_REVIEWS + "/file-review-status",
    objectType: {} as GeographicalScopeModel,
    callType: "get" as "get" | "post",
  },
  CFileReviewTypeDefinition: {
    endpoint: endpoints.FILE_REVIEWS + "/file-review-types",
    objectType: {} as GeographicalScopeModel,
    callType: "get" as "get" | "post",
  },
  CDiagnosticTestTypeDefinition: {
    endpoint: endpoints.MEDICAL_FILES + "/diagnostic-test-types",
    objectType: {} as DiagnosticTestTypeModel,
    callType: "get" as "get" | "post",
  },
  CBillingTypeDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/billing-types",
    objectType: {} as BillingTypeModel,
    callType: "get" as "get" | "post",
  },
  CRegionDefinition: {
    endpoint: endpoints.REGIONS,
    objectType: {} as BillingTypeModel,
    callType: "post" as "get" | "post",
  },
  CTreasuryTransactionTypeDefinition: {
    endpoint: endpoints.TREASURIES + "/treasury-transaction-types",
    objectType: {} as TreasuryTransactionTypeModel,
    callType: "get" as "get" | "post",
  },
  CAccountBasicTypeDefinition: {
    endpoint: endpoints.ACCOUNTS + "/account-basic-types",
    objectType: {} as AccountBasicTypeModel,
    callType: "get" as "get" | "post",
  },
  CServicePriceUpdateRequestStatusDefinition: {
    endpoint: endpoints.SERVICES + "/service-price-update-request-status",
    objectType: {} as ServicePriceUpdateRequestStatusModel,
    callType: "get" as "get" | "post",
  },
  CTransactionRequiresApprovalReasonDefinition: {
    endpoint: endpoints.CLAIMS + "/transaction-requires-approval-reasons",
    objectType: {} as TransactionRequiresApprovalReasonModel,
    callType: "post" as "get" | "post",
  },
  CBatchClaimWorkspaceStatusDefinition: {
    endpoint: endpoints.BATCHES + "/batch-claim-workspace-status",
    objectType: {} as WorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CPersonWorkspaceStatusDefinition: {
    endpoint: endpoints.PERSONS + "/person-workspace-status",
    objectType: {} as PersonWorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CApprovalTypeDefinition: {
    endpoint: endpoints.APPROVALS + "/approval-types",
    objectType: {} as ApprovalTypeModel,
    callType: "get" as "get" | "post",
  },
  CBillPaymentProcessingWorkspaceStatusDefinition: {
    endpoint:
      endpoints.BILL_PAYMENTS + "/bill-payment-processing-workspace-status",
    objectType: {} as BillPaymentProcessingWorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CApprovalSegmentDefinition: {
    endpoint: endpoints.APPROVALS + "/approval-segments",
    objectType: {} as ApprovalSegmentModel,
    callType: "get" as "get" | "post",
  },
  CChronicFormRequestStatusDefinition: {
    endpoint: endpoints.CHRONIC_FORMS + "/chronic-form-request-statuses",
    objectType: {} as ChronicFormRequestStatusModel,
    callType: "get" as "get" | "post",
  },
  CChronicFormRequestTypesDefinition: {
    endpoint: endpoints.CHRONIC_FORMS + "/chronic-form-request-types",
    objectType: {} as ChronicFormRequestTypeModel,
    callType: "get" as "get" | "post",
  },
  CDataFieldDefinition: {
    endpoint: endpoints.BASE_CONCEPTS + "/data-fields",
    objectType: {} as DataFieldModel,
    callType: "get" as "get" | "post",
  },
  CAdvancePaymentStatusDefinition: {
    endpoint: endpoints.ADVANCE_PAYMENTS + "/advance-payment-status",
    objectType: {} as AdvancePaymentStatusModel,
    callType: "get" as "get" | "post",
  },
  CApprovalCategoryDefinition: {
    endpoint: endpoints.APPROVALS + "/approval-categories",
    objectType: {} as ApprovalCategoryModel,
    callType: "get" as "get" | "post",
  },
  CProviderGroupMemberWorkspaceStatusDefinition: {
    endpoint: endpoints.PROVIDERS + "/provider-group-member-workspace-status",
    objectType: {} as ProviderGroupMemberWorkspaceStatusModel,
    callType: "get" as "get" | "post",
  },
  CDigitalPrescriptionStatusDefinition: {
    endpoint: endpoints.DIGITAL_PRESCRIPTIONS + "/digital-prescription-status",
    objectType: {} as DigitalPrescriptionStatusModel,
    callType: "get" as "get" | "post",
  },
  CCommunicationReceiverTypeDefinition: {
    endpoint: endpoints.APPLICATIONS + "/communication-receiver-types",
    objectType: {} as CommunicationReceiverTypeModel,
    callType: "get" as "get" | "post",
  },
  CInHouseProviderCompensationTypesDefinition: {
    endpoint: endpoints.IN_HOUSE + "/in-house-provider-compensation-types",
    objectType: {} as InHouseProviderCompensationTypeModel,
    callType: "get" as "get" | "post",
  },
  CInvoiceGeneratorTypesDefinition: {
    endpoint: endpoints.INVOICES + "/invoice-generator-types",
    objectType: {} as InvoiceGeneratorTypeModel,
    callType: "get" as "get" | "post",
  },
  CCheckupStatusDefinition: {
    endpoint: endpoints.CHECKUP + "/checkup-status",
    objectType: {} as CheckupStatusModel,
    callType: "get" as "get" | "post",
  },
});
