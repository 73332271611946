import { useContext } from "react";
import { LocalizationContext } from "../../../common/context/localizationContext";
import AppCard from "../../../common/components/cards/AppCard";
import AppCardBody from "../../../common/components/cards/AppCardBody";
import strings from "../../../base/strings";
import ECardModel from "../../../interfaces/eCards/ECardModel";
import LabelText from "../../../common/components/Labels/LabelText";

interface Props {
  eCard: ECardModel | null;
}
export default function ECardInsuredInfo({ eCard }: Props) {
  const { getName } = useContext(LocalizationContext);
  const MIN_WIDTH = 130;

  return (
    <AppCard>
      <AppCardBody
        orientation="horizontal"
        hasTitle={false}
        minComponentWidth={MIN_WIDTH}
        contentAlignment={"start"}
        numColumnsMobile={2}
        numColumns={3}
        numColumnsHighRes={4}
      >
        <LabelText
          labelTitle={strings.DATE_OF_BIRTH}
          labelValue={eCard?.DateOfBirth}
          orientation="vertical"
          minWidth={MIN_WIDTH}
        />
        <LabelText
          labelTitle={strings.CLASS}
          labelValue={getName(eCard?.ClassModel)}
          orientation="vertical"
          minWidth={MIN_WIDTH}
        />
        <LabelText
          labelTitle={strings.COVERAGE_PLAN}
          labelValue={getName(eCard?.ECardPlanCoverageModel.CoveragePlanModel)}
          orientation="vertical"
          minWidth={MIN_WIDTH}
        />
      </AppCardBody>
      <AppCardBody
        orientation="horizontal"
        hasTitle={false}
        minComponentWidth={MIN_WIDTH}
        contentAlignment={"start"}
        numColumnsMobile={2}
        numColumns={3}
        numColumnsHighRes={3}
      >
        <LabelText
          labelTitle={strings.POLICY_HOLDER_2}
          labelValue={getName(eCard?.ClientModel)}
          orientation="vertical"
          minWidth={MIN_WIDTH}
          disableFlex
          valueClasses="long-text"
          tooltipEnabled
        />
        <LabelText
          labelTitle={strings.JOIN_DATE}
          labelValue={eCard?.PolicyJoinDate}
          orientation="vertical"
          minWidth={MIN_WIDTH}
        />
        <LabelText
          labelTitle={strings.EXPIRY_DATE}
          labelValue={eCard?.ExpiryDate}
          orientation="vertical"
          minWidth={MIN_WIDTH}
        />
      </AppCardBody>
      <AppCardBody
        orientation="horizontal"
        hasTitle={false}
        minComponentWidth={MIN_WIDTH}
        contentAlignment={"start"}
        numColumns={3}
        numColumnsMobile={2}
      >
        <LabelText
          labelTitle={strings.MEDICAL_NETWORK}
          labelValue={getName(eCard?.ProviderGroupModel)}
          orientation="vertical"
          minWidth={MIN_WIDTH}
        />
        {eCard?.InsuranceCompanyModel && (
          <LabelText
            labelTitle={strings.INSURANCE_COMPANY}
            labelValue={getName(eCard?.InsuranceCompanyModel)}
            orientation="vertical"
            minWidth={MIN_WIDTH}
          />
        )}
      </AppCardBody>
    </AppCard>
  );
}
