import AppCard from "../../../common/components/cards/AppCard";
import React from "react";
import ECardNoteModel from "../../../interfaces/eCards/ECardNoteModel";
import AppCardBody from "../../../common/components/cards/AppCardBody";
import styleConstants from "../../../common/constants/styleConstants";
import SectionTitle from "../../../common/components/forms/SectionTitle";
import AppStackLayout from "../../../common/components/stack/AppStackLayout";

interface Props {
  cardNotes: ECardNoteModel[];
  minWidth?: number;
}
export default function ECardNotesPanel({ cardNotes, minWidth }: Props) {
  const MIN_COMPONENT_WIDTH = minWidth || 500;
  return (
    <AppCard>
      <AppCardBody
        hasTitle={false}
        orientation={"vertical"}
        minComponentWidth={MIN_COMPONENT_WIDTH}
        style={{ padding: "0 " + styleConstants.GAP_LARGEST + "px" }}
      >
        {React.Children.toArray(
          cardNotes.map((cardNote, i) => {
            return (
              <AppStackLayout orientation="vertical">
                <label
                  // className="titleItem side-message-box"
                  // style={{ whiteSpace: "break-spaces" }}
                  className="labelTitle"
                  style={{ whiteSpace: "break-spaces", textTransform: "none" }}
                  key={i}
                >
                  {cardNote.Note}
                </label>
                <SectionTitle title={""} overrideMargin />
              </AppStackLayout>
            );
          })
        )}
      </AppCardBody>
    </AppCard>
  );
}
