import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnBeforeUploadEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadProps,
} from "@progress/kendo-react-upload";
import { Error } from "@progress/kendo-react-labels";
import attachmentsApi from "../../api/attachments";
import strings from "../../base/strings";
import AttachmentTypeModel from "../../interfaces/attachments/AttachmentTypeModel";
import AppDropDownList from "../../common/components/dropDown/AppDropDownList";
import { LocalizationContext } from "../../common/context/localizationContext";
import { StackLayout } from "@progress/kendo-react-layout";
import styleConstants from "../../common/constants/styleConstants";
import { DropDownListFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import uiHelper from "../../common/helpers/uiHelper";
import { dummyItemID } from "../../common/constants/appConstants";
import AppCheckBox from "../../common/components/inputs/AppCheckBox";
import AppStackLayout from "../../common/components/stack/AppStackLayout";
import AppTextArea from "../../common/components/inputs/AppTextArea";
import messages from "../../base/messages";

interface Props extends UploadProps {
  // uploadFile?: (
  //   formData: FormData,
  //   file: UploadFileInfo,
  //   onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  // ) => Promise<ApiResponse<any, any>>;
  removeUploadedFile?: () => boolean;
  uploadFiles?: () => boolean;
  moduleCode: string;
  attachmentGroupCode?: string;
  onUploadFiles: (attachmentIDs: Array<number>) => void;
  attachmentTypeCode?: string;
  setUploading?: (loading: boolean) => void;
  attachmentDescription?: string;
  attachmentDate?: Date;
  enableRequestDescription?: boolean;
  showFileList?: boolean;
  customFileTypes?: Array<string>;
  additionalFormComponent?: {
    component: any;
    title: string;
    requiredValue: boolean;
  }[];
}
export default function AppUpload({
  // uploadFile,
  removeUploadedFile,
  uploadFiles,
  batch,
  multiple,
  moduleCode,
  onUploadFiles,
  attachmentTypeCode,
  setUploading,
  attachmentGroupCode,
  attachmentDescription,
  attachmentDate,
  disabled,
  enableRequestDescription = false,
  showFileList = true,
  customFileTypes,
  additionalFormComponent,

  ...rest
}: Props) {
  const MIN_LABEL_WIDTH = enableRequestDescription ? 165 : 120;
  const [attachmentTypes, setAttachmentTypes] = useState<
    Array<AttachmentTypeModel>
  >([]);

  const [selectedAttachmentType, setSelectedAttachmentType] =
    useState<AttachmentTypeModel | null>(null);

  // const [showAttachmentTypeDialog, setShowAttachmentTypeDialog] =
  //   useState(false);

  const [isInitializing, setIsInitializing] = useState(false);
  const [dropDownFilter, setDropDownFilter] = useState<string>();
  const [dropDownDataObjectList, setDropDownDataObjectList] = useState<
    AttachmentTypeModel[]
  >([]);
  const [opened, setOpened] = useState(false);

  const [requiredDescription, setRequiredDescription] =
    useState<boolean>(false);
  const [description, setDescription] = useState<string>(
    attachmentDescription || ""
  );
  const [validDescription, setValidDescription] = useState<boolean>(
    !!attachmentDescription
  );

  const fetchAttachmentTypes = async () => {
    const response = await attachmentsApi.getAttachmentTypes(
      moduleCode,
      attachmentGroupCode
    );
    if (response.ok) {
      setAttachmentTypes(response.data);
      setDropDownDataObjectList(response.data);
    }
    setIsInitializing(false);
  };

  const { translate, getNameField } = useContext(LocalizationContext);

  useEffect(() => {
    setIsInitializing(true);
    moduleCode !== "" && fetchAttachmentTypes();
    // eslint-disable-next-line
  }, []);

  const handleAdd = (event: UploadOnAddEvent) => {
    // if (uploadFile) uploadFile();
  };

  const handleOnProgress = (event: UploadOnProgressEvent) => {};

  const handleRemove = (event: UploadOnRemoveEvent) => {
    if (removeUploadedFile) removeUploadedFile();
  };

  const uploadFile = (
    formData: FormData,
    file: UploadFileInfo,
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ) => {
    return attachmentsApi.uploadAttachment(
      {
        AttachmentTypeCode: attachmentTypeCode || selectedAttachmentType?.Code,
        Description: requiredDescription ? description : attachmentDescription,
        AttachmentDate: attachmentDate
          ? uiHelper.getDateFormatQueryString(attachmentDate)
          : null,
      },
      formData,
      (progress: any) => {
        onProgress(
          file.uid,
          new ProgressEvent(file.uid, {
            lengthComputable: true,
            loaded: progress,
            total: 100,
          })
        );
      }
    );
  };
  const onSave = async (
    files: UploadFileInfo[],
    options: {
      formData: FormData;
      requestOptions: any;
    },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ) => {
    setUploading && setUploading(true);
    const promise = new Promise<{ uid: string }>((resolve, reject) => {
      const reader = new FileReader();
      const file = files[0];
      if (file) {
        if (
          requiredDescription &&
          uiHelper.isNullOrEmptyString(description, true)
        ) {
          // setValidDescription(false);
          return reject({ uid: file.uid });
        }

        const fileContent = file.getRawFile?.();

        reader.onloadend = async (e) => {
          const formData = new FormData();

          if (e.target?.result) {
            const fileData = new File([e.target?.result], file.name, {
              type: fileContent?.type,
            });

            formData.append(`File`, fileData);
            if (uploadFile) {
              const result = await uploadFile(formData, file, onProgress);
              if (result.ok) {
                setUploading && setUploading(false);
                const attachmentID = result.data;
                onUploadFiles([attachmentID]);
                setSelectedAttachmentType(null);
                return resolve({ uid: file.uid });
              } else {
                return reject({ uid: file.uid });
              }
            }
          }
        };
        if (fileContent) reader.readAsArrayBuffer(fileContent);
      }
    });
    return promise;
  };

  const handleOnBeforeUpload = async (event: UploadOnBeforeUploadEvent) => {
    // if (selectedAttachmentType && selectedAttachmentType.Code) {
    //   event.headers = {
    //     AttachmentTypeCode: selectedAttachmentType.Code,
    //   };
    // }
    // event.additionalData = {
    //   ["attachmentType"]: "hello",
    // };
  };

  const handleOnChange = (e: any) => {
    if (!e.target.value || e.target.value.ID === dummyItemID)
      setSelectedAttachmentType(null);
    else setSelectedAttachmentType(e.target.value);
  };

  const filterData = (filterValue: string) => {
    return filterBy(dropDownDataObjectList, {
      logic: "and",
      filters: [
        {
          field: getNameField(),
          operator: "contains",
          value: filterValue,
          ignoreCase: true,
        },
      ],
    });
  };

  const handleFilterChange = (event: DropDownListFilterChangeEvent) => {
    setDropDownFilter(event.filter.value);

    if (event.filter.value.length !== 0) {
      setDropDownDataObjectList(filterData(event.filter.value));
    } else {
      setDropDownDataObjectList(attachmentTypes);
    }
  };

  const onOpen = () => {
    setOpened(true);
    setDropDownFilter("");
    setDropDownDataObjectList(attachmentTypes);
  };

  return (
    <StackLayout
      orientation="vertical"
      gap={styleConstants.GAP_LARGE}
      style={{
        marginTop: styleConstants.MARGIN_LARGE,
        marginBottom: styleConstants.MARGIN_LARGE,
      }}
      className="appUpload"
    >
      <AppStackLayout
        orientation="horizontal"
        align={{ horizontal: "start" }}
        gap={20}
      >
        {enableRequestDescription && (
          <>
            <AppStackLayout
              orientation="horizontal"
              // gap={styleConstants.GAP_MEDIUM}
              align={{ horizontal: "start" }}
            >
              <span
                className="labelTitle"
                style={{ minWidth: MIN_LABEL_WIDTH }}
              >
                {translate(strings.SET_ATTACHMENT_DESCRIPTION)}
              </span>
              <AppCheckBox
                checked={requiredDescription}
                onChange={(e) => {
                  setRequiredDescription(e.value);
                  if (!e.value) setDescription(attachmentDescription || "");
                }}
              />
            </AppStackLayout>
            {requiredDescription && (
              <AppStackLayout
                orientation="vertical"
                align={{ horizontal: "start", vertical: "middle" }}
              >
                <span
                  className="labelTitle"
                  style={{ minWidth: MIN_LABEL_WIDTH }}
                >
                  {translate(strings.DESCRIPTION)}
                </span>
                <span className="labelValue" style={{ width: "300px" }}>
                  <AppTextArea
                    // placeholder={strings.DESCRIPTION}
                    value={description}
                    cols={2}
                    rows={3}
                    onChange={(e) => {
                      setDescription(e.value);
                      if (!uiHelper.isNullOrEmptyString(e.value, true))
                        setValidDescription(true);
                      else setValidDescription(false);
                    }}
                  />
                  {!validDescription && (
                    <Error>{translate(messages.FIELD_IS_REQUIRED)}</Error>
                  )}
                </span>
              </AppStackLayout>
            )}
          </>
        )}

        <AppStackLayout orientation="vertical">
          {additionalFormComponent &&
            additionalFormComponent?.map((item, idx) => (
              <AppStackLayout orientation="horizontal" key={idx} gap={0}>
                <span
                  className="labelTitle"
                  style={{ minWidth: MIN_LABEL_WIDTH, flex: "5%" }}
                >
                  {translate(item?.title)}
                </span>
                <span
                  className="labelValue"
                  style={{ width: "300px", flex: "95%" }}
                >
                  {item?.component}
                </span>
                {item.requiredValue && (
                  <Error>{translate(messages.FIELD_IS_REQUIRED)}</Error>
                )}
              </AppStackLayout>
            ))}
        </AppStackLayout>
      </AppStackLayout>
      {!isInitializing && !attachmentTypeCode && (
        <StackLayout
          orientation="horizontal"
          gap={styleConstants.GAP_LARGE}
          align={{ horizontal: "start", vertical: "middle" }}
        >
          <span className="labelTitle" style={{ minWidth: MIN_LABEL_WIDTH }}>
            {translate(strings.ATTACHMENT_TYPE)}
          </span>
          <span className="labelValue" style={{ width: "300px" }}>
            <AppDropDownList
              filterable
              onFilterChange={handleFilterChange}
              filter={dropDownFilter}
              data={dropDownDataObjectList}
              textField={getNameField()}
              value={selectedAttachmentType}
              onChange={handleOnChange}
              objectBinding
              transparentMode
              onOpen={onOpen}
              opened={opened}
              onClose={() => setOpened(false)}
              disabled={disabled}
            />
          </span>
        </StackLayout>
      )}
      <Upload
        batch={batch}
        showFileList={showFileList}
        multiple={multiple}
        defaultFiles={[]}
        withCredentials={false}
        saveUrl={onSave}
        //   removeUrl={onRemove}
        onAdd={handleAdd}
        onProgress={handleOnProgress}
        onRemove={handleRemove}
        onBeforeUpload={handleOnBeforeUpload}
        disabled={
          disabled
            ? true
            : attachmentTypeCode
              ? false
              : requiredDescription &&
                  uiHelper.isNullOrEmptyString(description, true)
                ? true
                : additionalFormComponent &&
                    additionalFormComponent?.filter((item) => {
                      return item.requiredValue === true;
                    })?.length! > 0
                  ? true
                  : selectedAttachmentType === null
        }
        restrictions={{
          maxFileSize: 26214400, //5242880(5MB), 26214400(25MB)
          ...(customFileTypes ? { allowedExtensions: customFileTypes } : {}),
        }}
      />
      {/* {showAttachmentTypeDialog && !isInitializing && (
        <AppSingleDropDownDialog
          title={strings.ATTACHMENT_TYPE}
          data={attachmentTypes}
          isMandatory={true}
          onCancel={handleOnCancelTypeSelection}
          onOk={handleOnTypeSelection}
        />
      )} */}
    </StackLayout>
  );
}
