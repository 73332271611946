import { create } from "apisauce";
import authStorage from "../storage/authStorage";
import apiActionStorage from "./../storage/apiActionStorage";

const apiClient = create({
  baseURL:
    (process.env.REACT_APP_HTTPS === "true" ? "https://" : "http://") +
    process.env.REACT_APP_API_URL +
    process.env.REACT_APP_VERSION,
});
apiClient.addAsyncRequestTransform(async (request) => {
  const token = await authStorage.getToken();
  if (token) {
    request.headers["Authorization"] = "Bearer " + token;
  }
  if (!request.headers["etpa-visa"]) {
    request.headers["etpa-visa"] = process.env.REACT_APP_ETPA_VISA;
  }
});

apiClient.axiosInstance.interceptors.request.use((request) => {
  apiActionStorage.storeLastRequestTime(new Date().getTime());
  if (process.env.REACT_APP_VIEW_AS_ENABLED === "true") {
    const viewAs = authStorage.getViewAsUser();
    if (
      viewAs &&
      process.env.REACT_APP_VIEW_AS_KEY &&
      process.env.REACT_APP_VIEW_AS_USER_PROPERTY
    ) {
      var myObject = JSON.parse(request.data);
      request.data = JSON.stringify({
        [process.env.REACT_APP_VIEW_AS_KEY]:
          viewAs[process.env.REACT_APP_VIEW_AS_USER_PROPERTY].ID,
        ...myObject,
      });
    }
  }
  return request;
});
//btoa()
export default apiClient;
